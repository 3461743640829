<template>
  <Modal :open="open" @close="$emit('close')">
    <div class="modal" >
      <div class="backdrop-close" v-on:click="$emit('close')"></div>

      <div class="modal-wrapper oracle-card">
        <div class="modal-container">
          <div class="modal-header">
            <img src="~/@/assets/xi.svg" style="width:128px;margin:auto;" alt="" />
          </div>

          <div class="modal-body">
            <div class="stake-box">
              <h1 style="font-weight:500;text-align:center;">XI STAKING</h1>
              <br />
              <p
                style="max-width:300px;margin:auto;display: flex; flex-flow: row; justify-content: space-between"
              >
                XI BALANCE:
                <b
                  ><input
                    :value="getFormattedXiBalance()"
                    readonly="true"
                    type="number"
                    style="font-weight:300;text-align:right;padding:unset;color#ececec;width:150px;"
                    placeholder="50000"
                /></b>
              </p>
              <p
                style="max-width:300px;margin:auto;display: flex; flex-flow: row; justify-content: space-between"
              >
                XI STAKED:
                <b
                  ><input
                    :value="getFormattedXiStaked()"
                    readonly="true"
                    type="number"
                    style="font-weight:300;text-align:right;padding:unset;color#ececec;width:150px;"
                    placeholder="50000"
                /></b>
              </p>
              <br />
              <p
                style="cursor:pointer; text-align:center;" class="button"
                v-on:click="$emit('chain', {showStakingConfirmModal: false, showSatcomModal: true})"
              >
                ORDER SATCOM HARDWARE
              </p>
              <br />
            </div>
          </div>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import { mapActions, mapState} from 'vuex';
import { ethers } from 'ethers';

export default {
  props: ['open'],
  methods: {
    getFormattedXiBalance() {
        return parseFloat(ethers.utils.formatUnits(this.$store.state.settings.balanceXI)).toFixed(2);
      },
      getFormattedXiStaked() {
        return parseFloat(ethers.utils.formatUnits(this.$store.state.settings.stakedXI)).toFixed(2);
      },
  }
};
</script>
