import Vue from 'vue';
import { ethers, utils } from 'ethers';
import detectEthereumProvider from '@metamask/detect-provider';
import { getExchangeRatesFromCoinGecko } from '@/helpers/utils';
// import assets from '@/helpers/assets.json';
import { abi as ierc20Abi } from '@/helpers/abi/IERC20.json';
import { abi as XiSpaceAbi } from '@/helpers/abi/XiSpace.json';
import { abi as StakingAbi } from '@/helpers/abi/Staking.json';
import RouterAbi from '@/helpers/abi/UniswapV2Router.json';
import PairAbi from '@/helpers/abi/UniswapV2Pair.json';
import paymentOptions from '@/helpers/payments.json';
import paymentAbi from '@/helpers/abi/Payment.json';
import SpaceswapAbi from '@/helpers/abi/SpaceswapV1Router.json';
import { Client, query } from 'faunadb';

const factoryAbi = [
  {
    constant: true,
    inputs: [
      {
        internalType: 'address',
        name: 'tokenA',
        type: 'address'
      },
      {
        internalType: 'address',
        name: 'tokenB',
        type: 'address'
      }
    ],
    name: 'getPair',
    outputs: [
      {
        internalType: 'address',
        name: 'pair',
        type: 'address'
      }
    ],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  }
];

const parseEther = ethers.utils.parseEther;

// eslint-disable-next-line
const CIDS = require('cids');

let provider: ethers.providers.Web3Provider;
let fallBackProvider: ethers.providers.BaseProvider;

const state = {
  pendingTxns: new Map<string, object>(),
  //pOlySaleAddr: '0xf1837904605Ee396CFcE13928b1800cE0AbF1357',
  //daiAddr: '0x6b175474e89094c44da98b954eedeac495271d0f',
  loading: false,
  address: null,
  fallBackNetwork: 'mainnet',
  name: '',
  balance: 0,
  claim: 0,
  minimumEth: 0,
  providedEth: 0,
  remainingEth: 0,
  network: '',
  exchangeRates: {},
  allowances: {},
  balances: {},
  authorized: false,
  allowanceTx: 0,
  saleTx: 0,
  confirmations: 1,

  contractOracle: '', // xi staking
  stakedXI: 0,
  reward: 0,
  admins: [
    '0x9E5aE89AfAcc87cF7dAc092f60896e65e27c3fD0',
    '0xb47292B7bBedA4447564B8336E4eD1f93735e7C7'
  ],
  buyOrbcomm: false,
  boughtOrbcomm: false,
  orbCommPrice: 0,
  priceDecimals: 0,
  orbcommOrderId: 0,
  orbcommSignature: undefined,
  orbCommResponse: undefined,
  orbCommOrders: [],
  orbCommOrder: null,
  orbcommAddress: {
    name: '',
    phone: '',
    street: '',
    aptNo: '',
    city: '',
    state: '',
    zip: '',
    country: '',
    email: ''
  },
  tokenBalance: ethers.utils.parseEther('0'),
  tokenDecimals: 6,
  tokenAllowance: 0,
  tokenAddress: '',
  tokenName: 'USDC',
  tokenPrices: {
    xi: 0,
    kappa: 0,
    gamma: 0,
    rho: 0,
    beta: 0
  },
  balanceRHO: 0,
  balanceBETA: 0,
  balanceGAMMA: 0,
  balanceKAPPA: 0,
  balanceXI: 0,

  allowanceRHO: 0,
  allowanceBETA: 0,
  allowanceGAMMA: 0,
  allowanceKAPPA: 0,
  allowanceXI: 0,
  stakingAllowanceXI: 0,
  paymentAllowanceUSD: 0,

  imageCID: '',

  isMM: false,

  isAllowanceOK: false,

  submissionReceived: false,

  faunaToken: undefined,

  contractBETA: {
    rinkeby: '0x4F9D70bCdC9d918C2B291Dec3FBD6f0f625e2300',
    homestead: '0x35F67c1D929E106FDfF8D1A55226AFe15c34dbE2'
  },
  contractRHO: {
    rinkeby: '0xc35923852b8cBedDc5645603ca6fDc1bAB1CCE9C',
    homestead: '0x3F3Cd642E81d030D7b514a2aB5e3a5536bEb90Ec'
  },
  contractKAPPA: {
    rinkeby: '0xaA2cD1cce445D4EbA6E660Fce7Fa8a706259A084',
    homestead: '0x5D2C6545d16e3f927a25b4567E39e2cf5076BeF4'
  },
  contractGAMMA: {
    rinkeby: '0x8762C3A3BBe92D590fCF4572429feb472f05Ef6f',
    homestead: '0x1E1EEd62F8D82ecFd8230B8d283D5b5c1bA81B55'
  },
  contractXI: {
    rinkeby: '0xA5374742a049c681d327E631A9936661C0d73482',
    homestead: '0x295B42684F90c77DA7ea46336001010F2791Ec8c'
  },
  contractBooking: {
    rinkeby: '0x49C2aB20f8BBC2BB33F2F3901E00518d1B36875e',
    homestead: '0xc27A52D821cD704916c316452Aa117329Cd53Cb9'
  },
  contractStaking: {
    rinkeby: '0xEd80230367ea86F9f8A3C1E20b58cC4cD7addf8F',
    homestead: '0x3D91E3cD7C77FDb7251d77c76AF879B35AC16213'
  },
  contractPayment: {
    rinkeby: '0x1A0E7630bCA4235db155074EAC1B7B4700bBC0F2',
    homestead: '0x7902926979411270044cA2f5ca99fb093F9004E0'
  },
  contractUSDC: {
    homestead: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48'
  },
  contractWETH: {
    homestead: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2'
  },
  contractUniswapV2Router: {
    homestead: '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D'
  },
  contractSpaceswapV1Router: {
    homestead: '0xDB11e8B92281Ff3528973e67086fCc514aFd1426'
  },
  addressGECSatcomOrder: '0xb47292B7bBedA4447564B8336E4eD1f93735e7C7',
  paymentOptions: {},
  submissions: []
};

const mutations = {
  set(_state, payload) {
    Object.keys(payload).forEach(key => {
      Vue.set(_state, key, payload[key]);
    });
  },
  setOrbcommAddress(_state, payload) {
    _state.orbcommAddress = payload.address;
  },
  updatePaymentOptions(_state, payload) {
    Vue.set(_state.paymentOptions, payload.key, payload.value);
  },
  update(_state, payload) {
    Vue.set(_state.paymentOptions, payload.key, payload.value);
  },
  updatePendingTxn(_state, payload) {
    _state.pendingTxns.set(payload.hash ? payload.hash : payload.transactionHash, {
      hash: payload.hash ? payload.hash : payload.transactionHash,
      confirmations: payload.confirmations
    });
  },
  updateTokenPrices(_state, payload) {
    _state.tokenPrices[payload.token] = payload.value;
  }
};

const actions = {
  uploadIPFS: async ({ commit, dispatch }, image) => {
    // @ts-ignore
    const response = await fetch('/.netlify/functions/saveImage', {
      method: 'POST',
      body: JSON.stringify({ image: image, address: state.address })
    });
    const json = await response.json();
    if (response.status !== 200) {
      console.error(json.error);
    } else {
      console.log(json.message);
      commit('set', {
        imageCID: json.message
      });
    }
  },
  free: async ({ commit, dispatch }) => {
    if (provider) {
      provider.removeAllListeners();
    }
  },
  init: async ({ commit, dispatch }) => {
    commit('set', { loading: true });
    // await dispatch('getExchangeRates');
    const ethereum = (await detectEthereumProvider({
      mustBeMetaMask: true
    })) as ethers.providers.ExternalProvider;

    if (ethereum) {
      provider = new ethers.providers.Web3Provider(ethereum, 'any');
      const nw = await provider.getNetwork();
      commit('set', {
        network: nw.name,
        isMM: true
      });
      // @ts-ignore
      ethereum.on('disconnect', () => {
        commit('set', {
          address: null
        });
      });
      // @ts-ignore
      ethereum.on('chainChanged', chainId => {
        provider.getNetwork().then(nw => {
          commit('set', {
            network: nw.name
          });
          dispatch('pull');
          dispatch('pullBookings');
        });
      });
      // @ts-ignore
      ethereum.on('accountsChanged', () => {
        const signer = provider.getSigner();
        signer
          .getAddress()
          .then(address => {
            commit('set', {
              address
            });
            dispatch('pull');
          })
          .catch(err => {
            commit('set', {
              address: null
            });
          });
      });
    } else {
      fallBackProvider = ethers.getDefaultProvider(
        'https://' + state.fallBackNetwork + '.infura.io/v3/7f1f6bde2f94467ba38f6094e5763359',
        'any'
      );
      const nw = await fallBackProvider.getNetwork();
      commit('set', {
        network: nw.name,
        isMM: false
      });
      console.log('using fallback provider');
    }
    commit('set', { loading: false });
    //@ts-ignore
    window.RADIO.emit('AFTER::INIT');
  },
  login: async ({ commit, dispatch }) => {
    if (provider) {
      try {
        console.log('LOGIN');
        await provider.send('eth_requestAccounts', []);
        const signer = provider.getSigner();
        const address = await signer.getAddress();
        const nw = await provider.getNetwork();

        commit('set', {
          address,
          network: nw.name,
          loading: false,
          paymentOptions: paymentOptions[nw.name]
        });
        dispatch('pull');
      } catch (error) {
        console.error(error);
      }
    } else {
      console.error('This website require MetaMask');
    }
  },
  pullStaking: async ({ commit, dispatch }) => {
    if (state.address) {
      const staking = new ethers.Contract(
        state.contractStaking[state.network],
        StakingAbi,
        provider
      );
      const contractXI = new ethers.Contract(state.contractXI[state.network], ierc20Abi, provider);

      const stakedXI = await staking.totalStakedFor(state.address);
      const stakingReward = await staking.getReward(state.address);
      const stakingAllowanceXI = await contractXI.allowance(
        state.address,
        state.contractStaking[state.network]
      );
      const balanceXI = await contractXI.balanceOf(state.address);
      commit('set', {
        stakedXI,
        balanceXI,
        stakingAllowanceXI,
        reward: stakingReward.__token
      });
    }
  },
  track: async ({ commit, dispatch }, txPromise) => {
    return txPromise.then(
      tx => {
        commit('updatePendingTxn', tx);
        tx.wait().then(
          function(receipt) {
            commit('set', { loading: false });
            console.log('receipt', receipt);
            commit('updatePendingTxn', receipt);
          },
          err => {
            commit('set', { loading: false });
            commit('updatePendingTxn', { hash: tx.hash, confirmations: -1 });
            console.log(err);
          }
        );
      },
      err => {
        commit('set', { loading: false });
        commit('updatePendingTxn', {}, { confirmations: -1 });
        console.log(err);
      }
    );
  },
  approveStaking: async ({ commit, dispatch }) => {
    if (state.address) {
      const signer = provider.getSigner();
      const contractXI = new ethers.Contract(state.contractXI[state.network], ierc20Abi, signer);
      return await dispatch(
        'track',
        contractXI.approve(
          state.contractStaking[state.network],
          ethers.BigNumber.from(
            '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'
          )
        )
      );
    }
  },
  stake: async ({ commit, dispatch }, amount) => {
    if (state.address) {
      commit('set', { loading: true });
      const signer = provider.getSigner();
      const staking = new ethers.Contract(state.contractStaking[state.network], StakingAbi, signer);
      return await dispatch('track', staking.stake(amount, '0x'));
    }
  },
  unstake: async ({ commit, dispatch }, amount) => {
    if (state.address) {
      commit('set', { loading: true });
      const signer = provider.getSigner();
      const staking = new ethers.Contract(state.contractStaking[state.network], StakingAbi, signer);
      staking.on('Unstaked', async (addr, amount, total, data) => {
        await dispatch('pullStaking');
      });
      await dispatch('track', staking.unstake(amount, '0x'));
    }
  },
  withdrawRewards: async ({ commit, dispatch }, amount) => {
    if (state.address) {
      const signer = provider.getSigner();
      const staking = new ethers.Contract(state.contractStaking[state.network], StakingAbi, signer);
      await dispatch('track', staking.withdraw(amount));
    }
  },
  pullBookings: async ({ commit, dispatch }) => {
    const defaultProvider = provider ? provider : fallBackProvider;
    const contractBooking = new ethers.Contract(
      state.contractBooking[state.network],
      XiSpaceAbi,
      defaultProvider
    );

    const xiDeployBlock = 14339278;
    const subFilter = contractBooking.filters.SUBMISSION();
    const submissions = await contractBooking.queryFilter(subFilter, xiDeployBlock);
    const submissionsData = submissions.map(submission => {
      return contractBooking.bookings(submission.args!.id);
    });

    const fullSubmissionData = await Promise.all(submissionsData);
    const submissionDataFixedCID = fullSubmissionData.map(submission => {
      const hex = Uint8Array.from(Buffer.from('1220' + submission.sha.slice(2), 'hex'));
      // eslint-disable-next-line
      let sub = { ...submission };
      sub.sha = new CIDS(0, 'dag-pb', hex).toString();
      return sub;
    });
    commit('set', { submissions: submissionDataFixedCID });
  },
  pull: async ({ commit, dispatch }) => {
    if (state.address) {
      const contractBETA = new ethers.Contract(
        state.contractBETA[state.network],
        ierc20Abi,
        provider
      );
      const contractRHO = new ethers.Contract(
        state.contractRHO[state.network],
        ierc20Abi,
        provider
      );
      const contractKAPPA = new ethers.Contract(
        state.contractKAPPA[state.network],
        ierc20Abi,
        provider
      );
      const contractGAMMA = new ethers.Contract(
        state.contractGAMMA[state.network],
        ierc20Abi,
        provider
      );
      const contractXI = new ethers.Contract(state.contractXI[state.network], ierc20Abi, provider);

      const balanceBETA = await contractBETA.balanceOf(state.address);
      const balanceRHO = await contractRHO.balanceOf(state.address);
      const balanceKAPPA = await contractKAPPA.balanceOf(state.address);
      const balanceGAMMA = await contractGAMMA.balanceOf(state.address);
      const balanceXI = await contractXI.balanceOf(state.address);

      const allowanceBETA = await contractBETA.allowance(
        state.address,
        state.contractBooking[state.network]
      );
      const allowanceRHO = await contractRHO.allowance(
        state.address,
        state.contractBooking[state.network]
      );
      const allowanceKAPPA = await contractKAPPA.allowance(
        state.address,
        state.contractBooking[state.network]
      );
      const allowanceGAMMA = await contractGAMMA.allowance(
        state.address,
        state.contractBooking[state.network]
      );
      const allowanceXI = await contractXI.allowance(
        state.address,
        state.contractBooking[state.network]
      );

      commit('set', {
        balanceBETA,
        balanceRHO,
        balanceKAPPA,
        balanceGAMMA,
        balanceXI,
        allowanceBETA,
        allowanceRHO,
        allowanceKAPPA,
        allowanceGAMMA,
        allowanceXI
      });
      // check that allowances are ok
      await dispatch('checkAllowances');
    }
  },
  checkAllowances: async ({ commit, dispatch }) => {
    if (state.address) {
      if (state.allowanceXI > 0) {
        commit('set', { isAllowanceOK: true });
      }
    }
  },
  setupAllowances: async ({ commit, dispatch }) => {
    if (state.address) {
      const signer = provider.getSigner();
      const contractBETA = new ethers.Contract(
        state.contractBETA[state.network],
        ierc20Abi,
        signer
      );
      const contractRHO = new ethers.Contract(state.contractRHO[state.network], ierc20Abi, signer);
      const contractKAPPA = new ethers.Contract(
        state.contractKAPPA[state.network],
        ierc20Abi,
        signer
      );
      const contractGAMMA = new ethers.Contract(
        state.contractGAMMA[state.network],
        ierc20Abi,
        signer
      );
      const contractXI = new ethers.Contract(state.contractXI[state.network], ierc20Abi, signer);

      contractXI.on('Approval', async (owner, spender, amount) => {
        if (owner == state.address) {
          const allowanceXI = await contractXI.allowance(
            owner,
            state.contractBooking[state.network]
          );
          commit('set', { allowanceXI });
          await dispatch('checkAllowances');
        }
      });

      contractGAMMA.on('Approval', async (owner, spender, amount) => {
        if (owner == state.address) {
          const allowanceGAMMA = await contractGAMMA.allowance(
            owner,
            state.contractBooking[state.network]
          );
          commit('set', { allowanceGAMMA });
          await dispatch('checkAllowances');
        }
      });

      contractKAPPA.on('Approval', async (owner, spender, amount) => {
        if (owner == state.address) {
          const allowanceKAPPA = await contractKAPPA.allowance(
            owner,
            state.contractBooking[state.network]
          );
          commit('set', { allowanceKAPPA });
          await dispatch('checkAllowances');
        }
      });

      contractRHO.on('Approval', async (owner, spender, amount) => {
        if (owner == state.address) {
          const allowanceRHO = await contractRHO.allowance(
            owner,
            state.contractBooking[state.network]
          );
          commit('set', { allowanceRHO });
          await dispatch('checkAllowances');
        }
      });

      contractBETA.on('Approval', async (owner, spender, amount) => {
        if (owner == state.address) {
          const allowanceBETA = await contractBETA.allowance(
            owner,
            state.contractBooking[state.network]
          );
          commit('set', { allowanceBETA });
          await dispatch('checkAllowances');
        }
      });

      await dispatch(
        'track',
        contractBETA.approve(
          state.contractBooking[state.network],
          ethers.BigNumber.from(
            '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'
          )
        )
      );
      await dispatch(
        'track',
        contractRHO.approve(
          state.contractBooking[state.network],
          ethers.BigNumber.from(
            '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'
          )
        )
      );
      await dispatch(
        'track',
        contractKAPPA.approve(
          state.contractBooking[state.network],
          ethers.BigNumber.from(
            '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'
          )
        )
      );
      await dispatch(
        'track',
        contractGAMMA.approve(
          state.contractBooking[state.network],
          ethers.BigNumber.from(
            '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'
          )
        )
      );
      await dispatch(
        'track',
        contractXI.approve(
          state.contractBooking[state.network],
          ethers.BigNumber.from(
            '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'
          )
        )
      );
    }
  },
  createBooking: async ({ commit, dispatch }, payload) => {
    if (state.address && state.imageCID) {
      const signer = provider.getSigner();
      const contractBooking = new ethers.Contract(
        state.contractBooking[state.network],
        XiSpaceAbi,
        signer
      );

      const cidHex = new CIDS(state.imageCID).multihash;
      contractBooking.on('SUBMISSION', async (id, sender) => {
        if (sender == state.address) {
          commit('set', { submissionReceived: true });
        }
      });
      await dispatch(
        'track',
        contractBooking.submit(
          payload.x,
          payload.y,
          payload.w,
          payload.h,
          payload.t,
          payload.d,
          cidHex.slice(2),
          payload.kappa,
          payload.gamma
        )
      );
    }
  },
  loading: ({ commit }, payload) => {
    commit('set', { loading: payload });
  },
  async getExchangeRates({ commit }) {
    const exchangeRates = await getExchangeRatesFromCoinGecko();
    commit('set', { exchangeRates });
  },

  async subscribeToStaking({ commit, dispatch }) {
    const staking = new ethers.Contract(state.contractStaking[state.network], StakingAbi, provider);
    staking.on('Staked', (addr, amount, total, bytes) => {
      if (addr == state.address) {
        dispatch('pullStaking');
        commit('set', { buyOrbcomm: true, loading: false });
      }
    });
  },
  async subscribeToXiApprovalForStaking({ commit, dispatch }) {
    const xiContract = new ethers.Contract(state.contractXI[state.network], ierc20Abi, provider);
    xiContract.on('Approval', async (owner, spender, amount) => {
      if (owner == state.address) {
        const stakingAllowanceXI = await xiContract.allowance(
          state.address,
          state.contractStaking[state.network]
        );
        commit('set', {
          stakingAllowanceXI
        });
      }
    });
  },
  async subscribeToUnstaking({ commit, dispatch }) {
    const staking = new ethers.Contract(state.contractStaking[state.network], StakingAbi, provider);
    staking.on('Unstaked', async (addr, amount, total, bytes) => {
      if (addr == state.address) {
        const staking = new ethers.Contract(
          state.contractStaking[state.network],
          StakingAbi,
          provider
        );
        const stakedXI = await staking.totalStakedFor(state.address);
        commit('set', { stakedXI });
      }
    });
  },
  async getOrbcommPrice({ commit }, key) {
    const contract = new ethers.Contract(
      state.contractPayment[state.network],
      paymentAbi,
      provider
    );
    const tokenContract = new ethers.Contract(key, ierc20Abi, provider);
    const priceDecimals = await tokenContract.decimals();
    const orbCommPrice = await contract.tokenAmounts(key);
    const paymentAllowanceUSD = await tokenContract.allowance(
      state.address,
      state.contractPayment[state.network]
    );
    commit('set', { orbCommPrice, priceDecimals, paymentAllowanceUSD });
  },

  orbcommPayment: async ({ commit, dispatch }, token) => {
    if (state.address) {
      commit('set', { loading: true });
      const signer = provider.getSigner();
      const payment = new ethers.Contract(state.contractPayment[state.network], paymentAbi, signer);
      return await dispatch('track', payment.makePayment(token));
    }
  },
  orbcommApproval: async ({ commit, dispatch }, token) => {
    if (state.address) {
      commit('set', { loading: true });
      const signer = provider.getSigner();
      const tokenContract = new ethers.Contract(token, ierc20Abi, signer);
      return await dispatch(
        'track',
        tokenContract.approve(state.contractPayment[state.network], state.orbCommPrice)
      );
    }
  },
  subscribeToTokenApproval({ commit }, token) {
    const tokenContract = new ethers.Contract(token, ierc20Abi, provider);
    tokenContract.on('Approval', async (owner, spender, amount) => {
      if (owner == state.address) {
        const paymentAllowanceUSD = await tokenContract.allowance(
          state.address,
          state.contractPayment[state.network]
        );
        commit('set', {
          paymentAllowanceUSD,
          loading: false
        });
      }
    });
  },
  subscribeToPayment({ commit }, token) {
    const paymentContract = new ethers.Contract(
      state.contractPayment[state.network],
      paymentAbi,
      provider
    );
    paymentContract.on('PaymentReceived', async (sender, token, amount, orderId) => {
      if (sender == state.address) {
        commit('set', {
          buyOrbcomm: false,
          boughtOrbcomm: true,
          loading: false,
          orbcommOrderId: orderId
        });
      }
    });
  },
  async getOrbcommSignature({ commit }) {
    const signer = provider.getSigner();
    const orderId = state.orbcommOrderId;
    const address = state.orbcommAddress;
    const message = `I'm Signing this to verify my order(${orderId}) is to be delivered to the following address: ${address.name}, ${address.street}, ${address.aptNo}, ${address.city}, ${address.state}, ${address.zip}, ${address.country} Phone: ${address.phone} Email: ${address.email}`;
    const orbcommSignature = await signer.signMessage(message);
    commit('set', { orbcommSignature });

    fetch('/.netlify/functions/signOrder', {
      method: 'POST',
      body: JSON.stringify({ address, orderId, signature: orbcommSignature })
    }).then(async response => {
      const json = await response.json();
      if (response.status !== 200) {
        console.error(json.error);
      } else {
        commit('set', { orbCommResponse: json });
      }
    });
  },
  async adminLogin({ commit }) {
    const signer = provider.getSigner();
    const address = state.address;
    const timestamp = Date.now() / 1000 + 3600;
    const message = `I'm signing this to verify I own the keys to the address ${address} and will be logged in till ${timestamp}`;
    const signature = await signer.signMessage(message);
    commit('set', { signature });

    return fetch('/.netlify/functions/login', {
      method: 'POST',
      body: JSON.stringify({ address, timestamp, signature })
    }).then(async response => {
      const json = await response.json();
      if (response.status !== 200) {
        console.error(json.error);
      } else {
        commit('set', { faunaToken: json.secret });
      }
    });
  },
  async getOrders({ commit, dispatch }) {
    if (!state.faunaToken) await dispatch('adminLogin');

    if (!state.faunaToken) return;
    const faunaClient = new Client({ secret: state.faunaToken });

    try {
      const orbCommOrders = await faunaClient.query(
        query.Map(
          query.Paginate(query.Documents(query.Collection('orders'))),
          query.Lambda(x => query.Get(x))
        )
      );
      commit('set', { orbCommOrders });
    } catch (e) {
      console.log(e);
    }
  },
  async getOrder({ commit, dispatch }, { id }) {
    if (!state.faunaToken) await dispatch('adminLogin');

    if (!state.faunaToken) return;
    const faunaClient = new Client({ secret: state.faunaToken });

    try {
      const orbCommOrder = await faunaClient.query(
        query.Get(query.Match(query.Index('order_by_id'), id))
      );
      commit('set', { orbCommOrder });
    } catch (e) {
      console.log(e);
    }
  },
  async getXITokenPrice({ commit }, { amountOut }) {
    const signer = provider.getSigner();
    const router = new ethers.Contract(
      state.contractUniswapV2Router[state.network],
      RouterAbi,
      signer
    );
    console.log(state.tokenAddress);
    const amounts = await router.getAmountsIn(parseEther(amountOut.toString()), [
      state.tokenAddress,
      state.contractWETH[state.network],
      state.contractXI[state.network]
    ]);
    commit('updateTokenPrices', { token: 'xi', value: amounts[0] });
  },
  async getBETATokenPrice({ commit }, { amountOut }) {
    const signer = provider.getSigner();
    const router = new ethers.Contract(
      state.contractUniswapV2Router[state.network],
      RouterAbi,
      signer
    );
    const amounts = await router.getAmountsIn(parseEther(amountOut.toString()), [
      state.tokenAddress,
      state.contractWETH[state.network],
      state.contractBETA[state.network]
    ]);
    commit('updateTokenPrices', { token: 'beta', value: amounts[0] });
  },
  async getGAMMATokenPrice({ commit }, { amountOut }) {
    const signer = provider.getSigner();
    const router = new ethers.Contract(
      state.contractUniswapV2Router[state.network],
      RouterAbi,
      signer
    );
    const amounts = await router.getAmountsIn(parseEther(amountOut.toString()), [
      state.tokenAddress,
      state.contractWETH[state.network],
      state.contractGAMMA[state.network]
    ]);
    commit('updateTokenPrices', { token: 'gamma', value: amounts[0] });
  },
  async getRHOTokenPrice({ commit }, { amountOut }) {
    const signer = provider.getSigner();
    const router = new ethers.Contract(
      state.contractUniswapV2Router[state.network],
      RouterAbi,
      signer
    );
    const amounts = await router.getAmountsIn(parseEther(amountOut.toString()), [
      state.tokenAddress,
      state.contractWETH[state.network],
      state.contractRHO[state.network]
    ]);
    commit('updateTokenPrices', { token: 'rho', value: amounts[0] });
  },
  async getKAPPATokenPrice({ commit }, { amountOut }) {
    const signer = provider.getSigner();
    const router = new ethers.Contract(
      state.contractUniswapV2Router[state.network],
      RouterAbi,
      signer
    );
    const amounts = await router.getAmountsIn(parseEther(amountOut.toString()), [
      state.tokenAddress,
      state.contractWETH[state.network],
      state.contractKAPPA[state.network]
    ]);
    commit('updateTokenPrices', { token: 'kappa', value: amounts[0] });
  },
  async setTokenAddress({ commit }, tokenAddress) {
    console.log('Setting token address', tokenAddress);
    await commit('set', { tokenAddress });
  },
  async getTokenPrices({ dispatch, commit }, tokens) {
    await dispatch('getXITokenPrice', { amountOut: tokens.xi });
    await dispatch('getBETATokenPrice', { amountOut: tokens.beta });
    await dispatch('getGAMMATokenPrice', { amountOut: tokens.gamma });
    await dispatch('getRHOTokenPrice', { amountOut: tokens.rho });
    await dispatch('getKAPPATokenPrice', { amountOut: tokens.kappa });
  },
  async initTokenSwapSubscriptions({ dispatch }, tokens) {
    const signer = provider.getSigner();
    const factory = new ethers.Contract(
      '0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f',
      factoryAbi,
      signer
    );
    const pair = await factory.getPair(state.tokenAddress, state.contractWETH[state.network]);
    if (!pair) return;
    const wethTokenPair = new ethers.Contract(pair, PairAbi, signer);
    const wethXIPair = new ethers.Contract(
      '0x7a99822968410431edd1eE75DAb78866e31cAf39',
      PairAbi,
      signer
    );
    const wethBETAPair = new ethers.Contract(
      '0x4849bb3F7fcaD49437f3107a578E063677424302',
      PairAbi,
      signer
    );
    const wethGAMMAPair = new ethers.Contract(
      '0xE46456153Cf0E767528F4a4cd3B613d5C4101E48',
      PairAbi,
      signer
    );
    const wethRHOPair = new ethers.Contract(
      '0xF28E099827CB05c9c35397859b4b626218C5a1cC',
      PairAbi,
      signer
    );
    const wethKAPPAPair = new ethers.Contract(
      '0xEFa2e1E46b83D572d01521C4C64845B0227B6314',
      PairAbi,
      signer
    );
    wethTokenPair.on('Swap', async (sender, amount0In, amount1In, amount0Out, amount1Out, to) => {
      dispatch('getTokenPrices', tokens);
    });
    wethXIPair.on('Swap', async (sender, amount0In, amount1In, amount0Out, amount1Out, to) => {
      await dispatch('getXITokenPrice', { amountOut: tokens.xi });
    });
    wethGAMMAPair.on('Swap', async (sender, amount0In, amount1In, amount0Out, amount1Out, to) => {
      await dispatch('getGAMMATokenPrice', { amountOut: tokens.gamma });
    });
    wethKAPPAPair.on('Swap', async (sender, amount0In, amount1In, amount0Out, amount1Out, to) => {
      await dispatch('getKAPPATokenPrice', { amountOut: tokens.kappa });
    });
    wethBETAPair.on('Swap', async (sender, amount0In, amount1In, amount0Out, amount1Out, to) => {
      await dispatch('getBETATokenPrice', { amountOut: tokens.beta });
    });
    wethRHOPair.on('Swap', async (sender, amount0In, amount1In, amount0Out, amount1Out, to) => {
      await dispatch('getRHOTokenPrice', { amountOut: tokens.rho });
    });
  },
  async getTokenAllowance({ commit }) {
    const signer = provider.getSigner();

    const contract = new ethers.Contract(state.tokenAddress, ierc20Abi, signer);
    console.log(state.tokenAddress);
    const tokenAllowance = await contract.allowance(
      state.address,
      state.contractSpaceswapV1Router[state.network]
    );
    console.log(tokenAllowance);
    commit('set', { tokenAllowance });
  },
  async setTokenName({ commit }, tokenName) {
    commit('set', { tokenName });
  },
  async getTokenApproval({ commit }, { amount }) {
    const signer = provider.getSigner();
    const addr = state.contractSpaceswapV1Router[state.network];
    const contract = new ethers.Contract(state.tokenAddress, ierc20Abi, signer);

    await contract.approve(addr, amount);
    contract.on('Approval', async (owner, spender, amount) => {
      if (owner == state.address && spender == addr) {
        commit('set', {
          tokenAllowance: amount
        });
      }
    });
  },
  async getTokenBalance({ commit }) {
    const signer = provider.getSigner();
    const addr = state.address;
    const contract = new ethers.Contract(state.tokenAddress, ierc20Abi, signer);

    const tokenBalance = await contract.balanceOf(addr);
    commit('set', { tokenBalance });
  },
  async spaceswap({ commit }, { total, amounts, tokens }) {
    const signer = provider.getSigner();
    const contract = new ethers.Contract(
      state.contractSpaceswapV1Router[state.network],
      SpaceswapAbi,
      signer
    );
    const spaceTokens = [
      state.contractXI[state.network],
      state.contractRHO[state.network],
      state.contractBETA[state.network],
      state.contractGAMMA[state.network],
      state.contractKAPPA[state.network]
    ];
    const amountsIn = [amounts.xi, amounts.rho, amounts.beta, amounts.gamma, amounts.kappa];
    const amountsOut = [
      ethers.utils.parseEther(tokens.xi.toFixed(18).toString()),
      ethers.utils.parseEther(tokens.rho.toFixed(18).toString()),
      ethers.utils.parseEther(tokens.beta.toFixed(18).toString()),
      ethers.utils.parseEther(tokens.gamma.toFixed(18).toString()),
      ethers.utils.parseEther(tokens.kappa.toFixed(18).toString())
    ];
    const paths = new Array(5);
    for (let i = 0; i < paths.length; ++i) {
      paths[i] = [state.tokenAddress, state.contractWETH[state.network], spaceTokens[i]];
    }
    console.log('Dispatching');
    await contract.swapTokenForExactSpaceTokens(
      state.tokenAddress,
      total,
      amountsIn,
      amountsOut,
      paths
    );
  }
};
interface Tokens {
  xi;
  kappa;
  gamma;
  rho;
  beta;
}
export default {
  state,
  mutations,
  actions
};
