<template>
    <div class="hexagon-loader">
    <svg class="hexagon" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 129.78 150.37">
    <path class="hexagon-base" d="M64.9519052838329 0L129.9038105676658 37.5L129.9038105676658 112.5L64.9519052838329 150L0 112.5L0 37.5Z"/>
    <path class="hexagon-line-animation" d="M64.9519052838329 0L129.9038105676658 37.5L129.9038105676658 112.5L64.9519052838329 150L0 112.5L0 37.5Z"/>
    </svg>
    </div>
</template>
<script>
export default {
};
</script>
