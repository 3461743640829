import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Home from '@/views/Home.vue';
import About from '@/views/About.vue';
import Verify from '@/views/Verify.vue';
import Claim from '@/views/Claim.vue';
import Stream from '@/views/Stream.vue';
import Placement from '@/views/Placement.vue';
import Oracle from '@/views/Oracle.vue';
import Pixel from '@/views/Pixel.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  { path: '/', name: 'home', component: Home },
  { path: '/about', name: 'about', component: About },
  { path: '/verify', name: 'verify', component: Verify },
  { path: '/stream', name: 'stream', component: Stream },
  { path: '/claim', name: 'claim', component: Placement },
  { path: '/oracle', name: 'oracle', component: Oracle },
  { path: '/pixel', name: 'pixel', component: Pixel }
];

const router = new VueRouter({
  routes
});

export default router;
