<template>
  <div class="full-wrapper" style="background-size:cover;background-color:#2a2a2e;">
    <div
      class="about-wrapper"
      style="backdrop-filter:blur(10px); display:flex;flex-flow:column;justify-content:center;height:100vh;background-color"
    >
      <div class="about-text">
        <router-link :to="{ name: 'about' }">
          <h1 class="xititle">COMING SOON</h1>
        </router-link>
        <div style="height:2rem;width:0px;background-color:#ececec;margin:0px auto ;"></div>
        <div
          class="row"
          style="margin:auto;display:flex;flex-flow:row;justify-content:center;width:100%;"
        >
          <router-link :to="{ name: 'oracle' }" style="  margin-right: 1px;">
            <p class="home-button">METAORACLE</p>
          </router-link>
          <router-link :to="{ name: 'claim' }">
            <p class="home-button">XI CLAIMING</p>
          </router-link>
          <router-link :to="{ name: 'home' }">
            <p class="home-button">HOME</p>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  data() {
    return {
      form: {
        quantity: ''
      },
      modalLoginOpen: false,
      modalMakepotionOpen: false
    };
  },
  components: {}
};
</script>
<style></style>
