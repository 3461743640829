import Vue from 'vue';
//import VueUi from '@vue/ui';
import VueI18n from 'vue-i18n';
import { upperFirst, camelCase } from 'lodash';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import { formatTs } from '@/helpers/utils';
import messages from '@/helpers/messages.json';
import numberFormats from '@/helpers/number.json';
import VueParticles from 'vue-particles';
import '@/style.scss';

import VueKonva from 'vue-konva';
import VueVirtualScroller from 'vue-virtual-scroller';
import VueFriendlyIframe from 'vue-friendly-iframe';
import VueSidebarMenu from 'vue-sidebar-menu';
import 'vue-sidebar-menu/dist/vue-sidebar-menu.css';
import './EE.js';
import VueTelInput from 'vue-tel-input';
import VueShepherd from 'vue-shepherd';
import 'shepherd.js/dist/css/shepherd.css';
import HistogramSlider from 'vue-histogram-slider';
import 'vue-histogram-slider/dist/histogram-slider.css';
import RoundSlider from 'vue-round-slider';
import VCalendar from 'v-calendar';
import VueDragscroll from "vue-dragscroll";

// Use plugin with defaults
Vue.use(VCalendar, {});
Vue.component(HistogramSlider.name, HistogramSlider);
Vue.use(VueShepherd);
Vue.use(VueTelInput);
Vue.use(VueSidebarMenu);
Vue.use(VueFriendlyIframe);
Vue.use(VueVirtualScroller);
Vue.use(VueKonva);
Vue.use(VueParticles);
Vue.use(VueI18n);
Vue.use(VueDragscroll);

const i18n = new VueI18n({ locale: 'en', messages, numberFormats });

const requireComponent = require.context('@/components', true, /[\w-]+\.vue$/);
requireComponent.keys().forEach(fileName => {
  const componentConfig = requireComponent(fileName);
  const componentName = upperFirst(camelCase(fileName.replace(/^\.\//, '').replace(/\.\w+$/, '')));
  Vue.component(componentName, componentConfig.default || componentConfig);
});

Vue.filter('formatTs', value => formatTs(value));

Vue.config.productionTip = false;

// global event bus
//@ts-ignore
window.RADIO = window.RADIO || new window.EventEmitter();

new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app');

setTimeout(() => {
  window.onfocus = () => {
    //@ts-ignore
    window.RADIO.emit('tabfocus');
  };
}, 2000);
