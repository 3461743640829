<template>
  <div :height="windowHeight + 'px' + '!important'">
    <div style="" class="clock nomobile">
      <img
        src="~/@/assets/earthoutline.svg"
        style="border-radius: 4rem;position:absolute;width:50px;margin:1rem auto;right:5px;bottom:15px;animation:glow2 1s ease infinite;"
        alt=""
      />
      <img
        src="~/@/assets/circleopen2.svg"
        style="border-radius: 4rem;position:absolute;width:60px;margin:1rem auto;right:0px;bottom:10px;animation:spinsecond 60s linear infinite;"
        alt=""
      />
      <p style="  position: absolute;left: 64px; bottom: 54px;font-size: 1rem !important;">UTC</p>
      <img
        src="~/@/assets/exchange.svg"
        style="position: absolute;width: 16px;margin: 1rem auto;left: 76px;bottom: 32px;"
        alt=""
      />
      <p
        style="  position: absolute;text-shadow:0px 0px 5px #ececec;color:#ececec;left: 68px; bottom: 14px;font-size: 1rem !important;"
      >
        LTC
      </p>
      <img
        src="~/@/assets/moon.svg"
        style="border-radius: 4rem;position:absolute;width:50px;margin:1rem auto;left:5px;bottom:15px;animation:glow 0.9843529666671s ease infinite;"
        alt=""
      />
      <img
        src="~/@/assets/circleopen.svg"
        style="border-radius: 4rem;position:absolute;width:60px;margin:1rem auto;left:0px;bottom:10px;animation:spinsecond 59.061178000026s linear infinite;"
        alt=""
      />
      <h2
        style="font-size: 1rem;
    font-weight: 200;text-align:center;"
      >
        {{ dateTime }}
      </h2>
    </div>
    <div id="app" class="" style="">
        <router-view :key="$route.path" />
    </div>
  </div>
</template>

<script>
import Countdown from 'vuejs-countdown';
import globalTime from 'global-time';
import { mapState, mapActions } from 'vuex';

export default {
  computed: {
    ...mapState(['settings'])
  },
  methods: {
    handleScroll() {
      this.scrolled = window.scrollY > 0;
    },
    currentDateTime() {
      const current = new Date();
      const date = current.getFullYear() + '-' + (current.getMonth() + 1) + '-' + current.getDate();
      const time = current.getHours() + ':' + current.getMinutes() + ':' + current.getSeconds();
      const dateTime = date + ' ' + time;

      return dateTime;
    },
    onResize() {
      this.windowHeight = window.innerHeight;
    },
    ...mapActions(['init','free'])
  },
  async created() {
    if(window.ethereum) window.ethereum.autoRefreshOnNetworkChange = false;
    window.addEventListener('scroll', this.handleScroll);
  },
  data() {
    return {
      scrolled: false,
      dateTime: null,
      windowHeight: window.innerHeight,
      txt: ''
    };
  },

  watch: {
    windowHeight(newHeight, oldHeight) {
      this.txt = `it changed to ${newHeight} from ${oldHeight}`;
    }
  },

  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
    this.timer = setInterval(() => {
      this.dateTime = this.currentDateTime();
    }, 1000);
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
    clearInterval(this.timer);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
    this.free();
  }
};
</script>

<style></style>
