<template>
  <div class="vsm--link">
    <vue-base64-file-upload
      accept="image/png,image/jpeg"
      input-class="icon download globalinput vsm--link_hover"
      :disable-preview="true"
      :max-size="customImageMaxSize"
      aria-placeholder=""
      placeholder=""
      @size-exceeded="onSizeExceeded"
      @file="onFile"
      @load="onLoad"
    />
  </div>
</template>
<style>
.globalinput {
  background-color: #1e1e21;
  text-align: center;
  border-radius: 3px;
}
</style>
<script>
import VueBase64FileUpload from 'vue-base64-file-upload';

export default {
  components: {
    VueBase64FileUpload
  },
  methods: {
    onLoad(dataUri) {
      this.$emit('file', dataUri);
      window.RADIO.emit('lol', dataUri);
      // console.log(file); // file object
    },
    onFile(file) {
      // console.log(file); // file object
    },
    onSizeExceeded(size) {
      alert(`Image ${size}Mb size exceeds limits of ${this.customImageMaxSize}Mb!`);
    }
  }
};
</script>
