<template>
  <Modal :open="open" @close="$emit('close')">
    <div class="modal">
      <div class="backdrop-close" v-on:click="$emit('close')"></div>
      <div class="modal-wrapper satcom-card">
        <div class="modal-container">
          <div class="modal-header">
            <img src="~/@/assets/xi.svg" style="width:128px;margin:auto;" alt="" />
          </div>

          <div class="modal-body">
            <div class="modal-box" v-if='response === undefined'>
                <h1 style="font-weight:500;text-align:center;">SATCOM PAYMENT CONFIRMED</h1>
                <br>
                <p> Please sign to verify address and order number.</p>
                <p>I'm Signing this to verify my order({{orderId}}) is to be delivered to the following address: 
                  {{address.name}}<br>
                  {{address.street}}<br>
                  {{address.aptNo}}<br v-if='address.aptNo' />
                  {{address.city}}, {{address.state}}<br />
                  {{address.zip}}, {{address.country}}<br />
                  Phone: {{address.phone}}<br />
                <br >
                <form class="satcomform">
                    <div class="satcomrow" style="display:flex;flex-flow:row;justify-content:space-between;">
                        
                        <button class="button" type="button" style="background-color:#7a7a7b!important;max-width:300px;margin: auto;" v-on:click="dispatchSignature()">
                            SIGN
                        </button>
                    </div>
                </form>
            </div>
            <div class="modal-box" v-else>
                <h1 style="font-weight:500;text-align:center;">SATCOM ORDER {{response.status}}</h1>
                <p v-if='response.message !== undefined'>{{response.message}}</p>
                <p v-else>
                  {{response.data.name}}<br />
                  {{response.data.address}}<br />
                  {{response.data.aptNo}}<br />
                  {{response.data.city}}, {{response.data.state}}<br />
                  {{response.data.zip}}, {{response.data.country}}<br />
                  Phone: {{response.data.phone}}
                  Email: {{response.data.email}}

                </p>
                <button class="button" style="background-color:#7a7a7b!important;max-width:300px;margin: auto;" v-on:click="$emit('close');">
                    CLOSE
                </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { ethers } from 'ethers';

export default {
  props: ['open'],
  data() {
    return {
      isLoading: false,
      payment: '',
      
    };
  },
  computed: { ...mapState({
          address: state => state.settings.orbcommAddress,
          orderId: state => state.settings.orbcommOrderId,
          response: state => state.settings.orbCommResponse,
      }),
      formattedPrice() {return ethers.utils.formatUnits(this.price, this.decimals)},
  },

  methods: {
        ...mapActions(['getOrbcommSignature']),
      async dispatchSignature() {
          await this.getOrbcommSignature();
      },
  },
};
</script>
