<template>
  <div>
    <vue-friendly-iframe class="pixelart" src="/pixelart.html"></vue-friendly-iframe>
  </div>
</template>
<style>
.pixelart > iframe {
  height: 100vh;
  width: 100%;
}
</style>
<script>
/*
import { ReactInVue } from 'vuera';
import { PixelArtEditor } from './PixelArtEditor/index.js';

export default {
  components: { pixel: ReactInVue(PixelArtEditor) }
};
*/
export default {
  mounted() {
    console.log('');
  },
  components: {}
};
</script>
