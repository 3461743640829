<template>
  <Modal :open="open" @close="$emit('close')">
    <div class="modal">
        <div class="backdrop-close" v-on:click="$emit('close')"></div>

        <div class="modal-wrapper oracle-card">
            <div class="modal-container">

                <div class="modal-header">
                    <img src="~/@/assets/xi.svg" style="width:128px;margin:auto;" alt="">
                </div>

                <div class="modal-body">
                    <div class="stake-box">

                        <h1 style="font-weight:500;text-align:center;">XI STAKING</h1>
                        <br>
                        <p style="max-width:300px;margin:auto;display: flex; flex-flow: row; justify-content: space-between">
                            XI BALANCE: <b>{{getFormattedXiBalance()}}</b>
                        </p>
                        <p style="max-width:300px;margin:auto;display: flex; flex-flow: row; justify-content: space-between">
                            XI STAKED: <b>{{getFormattedXiStaked()}}</b>
                        </p> 
                        <p style="max-width:300px;margin:auto;display: flex; flex-flow: row; justify-content: space-between">
                            XI REWARD: <b>{{getFormattedXiReward()}}</b>
                        </p> 

                        <br>
                        <p class="button" style="cursor:pointer; text-align:center; margin-bottom: 1rem!important;" v-on:click="$emit('chain',{showStakingModal: false, showSatcomModal: true})">ORDER SATCOM HARDWARE<br></p>
                        <p style="max-width:300px;margin:auto;display: flex; flex-flow: row; justify-content: space-between">
                            STAKE XI: <b><input v-model='inputStake' type="number" style="padding:0.25rem;box-shadow:inset 2px 2px 5px #0000005e, inset -2px -2px 5px #ffffff0f;text-align:right;font-weight:300;text-align:right;padding:unset;color#ececec;width:150px;" placeholder="50000"></b>
                        </p>
                    </div>
                  <div v-if='isLoading'>
                            <!--Animate here -->
                            <div class="row" style="margin: 1rem auto; "> 
                                <button class="button" style="background-color:#7a7a7b!important;max-width:300px;margin: auto;  border-color:#111;">                                
                                <img style="width:32px;margin:auto;animation:spinloader 1s ease infinite;margin-bottom: -6px;" src="~/@/assets/circleopen.svg">
                                </button>
                            </div>
                    </div>
                    <div v-else class="row" style="margin: 1rem auto; margin-bottom: 0rem">
                        <button v-if='bigStake && stakingAllowance && stakingAllowance.gte(bigStake) && balanceXI.gte(bigStake) && bigStake.gt(0)' class="button" style="background-color:#7a7a7b!important;max-width:300px;margin: auto;" v-on:click='dispatchStake()'>
                            STAKE 
                        </button>
                        <button v-else-if='bigStake && stakingAllowance && stakingAllowance.lt(bigStake) && balanceXI.gte(bigStake) && bigStake.gt(0)' class="button" style="background-color:#7a7a7b!important;max-width:300px;margin: auto;" v-on:click='dispatchApprove()'>
                            APPROVE
                        </button>  
                        <a href='https://rena.finance/#/renaswap/' target='blank_' v-else-if='bigStake && bigStake.gt(0)' class="button" style="background-color:#7a7a7b!important;max-width:300px;margin: auto;">
                            INSUFFICIENT BALANCE
                        </a>   
                        <button v-else class="button" style="background-color:#7a7a7b!important;max-width:300px;margin: auto;  border-color:#111;">
                            INSUFFICIENT STAKE
                        </button>                          
                        <button v-if='stakedXI && bigStake && stakedXI.gt(0) && bigStake.gt(0) && stakedXI.gte(bigStake)' class="button" style="background-color:#7a7a7b!important;max-width:300px;margin: auto;" v-on:click='dispatchUnstake()'>
                            UNSTAKE
                        </button>   
                        <button v-if='reward && reward.gt(0)' class="button" style="background-color:#7a7a7b!important;max-width:300px;margin: auto;" v-on:click='dispatchWithdraw()'>
                            REWARDS 
                        </button>                                                                  
                    </div>
                </div>

            </div>
        </div>
    </div>
  </Modal>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex';
import { ethers } from 'ethers';

export default {
  props: ['open'],
  data() {
    return {
      isLoading: false,
      service: '',
      inputStake: '0'
    };
  },
  computed: mapState({
      stakingAllowance: state => state.settings.stakingAllowanceXI,
      balanceXI: state => state.settings.balanceXI,
      stakedXI: state => state.settings.stakedXI,
      buyOrbcomm: state => state.settings.buyOrbcomm,
      bigStake(){ return ethers.utils.parseEther(this.inputStake ? this.inputStake : "0")},
      loading: state => state.settings.loading,
      reward: state => state.settings.reward,
      
  }),
  watch: {
      async open(value) {
          if(value === true) {
            await this.pullStaking()
            await this.subscribeToXiApprovalForStaking();
            await this.subscribeToStaking();            
          }
      },
      buyOrbcomm(value) {
          if(value === true) {
            this.$emit('chain', {showStakingModal:false, showStakingConfirmModal:true})    
          }
      },
      loading() {
          this.isLoading = this.loading;
      }
  },
  methods: {
      ...mapActions(['pullStaking', 'approveStaking', 'stake', 'subscribeToStaking', 'subscribeToUnstaking','unstake','withdrawRewards', 'subscribeToXiApprovalForStaking']),
      ...mapMutations(['set']),
      async dispatchApprove() {

          await this.approveStaking();
      },
      async dispatchStake() {
          
          await this.stake(this.bigStake);
          this.inputStake = '0'
      },
      async dispatchUnstake() {
          await this.subscribeToUnstaking();
          await this.unstake(this.bigStake);
          this.inputStake = '0'
      },
      async dispatchWithdraw() {
          await this.withdrawRewards(this.$store.state.settings.stakedXI);
      },
      getFormattedXiBalance() {
        return parseFloat(ethers.utils.formatUnits(this.$store.state.settings.balanceXI)).toFixed(2);
      },
      getFormattedXiStaked() {
        return parseFloat(ethers.utils.formatUnits(this.$store.state.settings.stakedXI)).toFixed(2);
      },
      getFormattedXiReward() {
        return parseFloat(ethers.utils.formatUnits(this.$store.state.settings.reward)).toFixed(2);
      },
  }
};
</script>
