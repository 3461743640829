<template>
  <Modal :open="open" @close="$emit('close')">
    <div class="modal">
      <div class="backdrop-close" v-on:click="$emit('close')"></div>

      <div
        class="error-modal-wrapper"
        style="color:red!important;background-color:yellow!important;padding:2rem;"
      >
        <div class="">
          <div class="row">
            <img src="~/@/assets/warning.svg" style="margin:auto;width:128px;margin:auto;" alt="" />
          </div>

          <h2 style="text-align:center;font-weight:900!important;">
            ERROR <b>INELIGABLE COUNTRY CODE</b>
          </h2>
          <br />
          <div style="padding:1rem;" class="row">
            <ul style="padding:1rem;">
              <li>AFGHANISTAN</li>
              <li>BELARUS</li>
              <li>BURMA</li>
              <li>CENTRAL AFRICAN REPUBLIC</li>
              <li>CHINA (PEOPLES REPUBLIC)</li>
              <li>CUBA</li>
              <li>CYPRUS</li>
              <li>DEMOCRATIC REPUBLIC OF THE CONGO</li>
              <li>ERITREAS</li>
              <li>HAITI</li>
              <li>IRAN</li>
            </ul>
            <ul style="padding:1rem;">
              <li>IRAQ</li>
              <li>KYRGYZSTAN</li>
              <li>LEBANON</li>
              <li>LIBYA</li>
              <li>NORTH KOREA</li>
              <li>RUSSIA</li>
              <li>SOMALIA</li>
              <li>SOUTH SUDAN</li>
              <li>SYRIA</li>
              <li>VENEZUUELA</li>
              <li>ZIMBABWE</li>
            </ul>
          </div>
          <br />
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  props: ['open'],
  data() {
    return {
      isLoading: false,
      service: ''
    };
  },
  methods: {}
};
</script>
