//https://en.wikipedia.org/wiki/HSL_and_HSV#From_RGB
const RGBToHSB = (r, g, b) => {
  r /= 255;
  g /= 255;
  b /= 255;
  const v = Math.max(r, g, b),
    n = v - Math.min(r, g, b);
  const h = n === 0 ? 0 : n && v === r ? (g - b) / n : v === g ? 2 + (b - r) / n : 4 + (r - g) / n;
  return [60 * (h < 0 ? h + 6 : h), v && (n / v) * 100, v * 100];
};

const usdPrices = {
    xi: 0.139,
    kappa: 18.63,
    gamma: 20.82,
    rho: 238.7,
    beta: 228.09
}


export const calculatePrice = ({seconds, x, y, w, h, pixelsRGB}) => {

    const cost = { 
        pixel: 0, 
        width: w, 
        height: h, 
        x, 
        y, 
        seconds, 
        rho: 0,
        beta: 0,
        kappa: 0,
        gamma: 0,
        calcKappa: 0,
        calcGamma: 0,
    }

    if(pixelsRGB.length !== h*w) {
        throw Error("pixelcount must be width * height")
    }

    pixelsRGB.forEach( p => {
        const HSBPixel = RGBToHSB(p.red, p.green, p.blue)
        cost.pixel += HSBPixel[0]+HSBPixel[1]+HSBPixel[2]

        //cost.hue += HSBPixel[0]
        //cost.sat += HSBPixel[1]
        //cost.bright += HSBPixel[2]

        cost.calcKappa += HSBPixel[0] + HSBPixel[1]  // hue + sat
        cost.calcGamma += HSBPixel[2]  // brightness

    })


    // xi
    const _xd   = 10
    cost.xi     = cost.seconds / _xd
    cost._usdXi = cost.xi * usdPrices.xi

    // kappa
    const _kgd  = 100000
    cost.kappa = cost.calcKappa / _kgd
    cost._usdKappa = cost.kappa * usdPrices.kappa

    // gamma
    cost.gamma = cost.calcGamma / _kgd
    cost._usdGamma = cost.gamma * usdPrices.gamma

    // rho
    const _rbd = 100
    cost.rho   = ((h/1080) * 6790) / _rbd;
    cost._usdRho = cost.rho * usdPrices.rho

    // beta
    cost.beta  = ((w/1200) * 6790) / _rbd;
    cost._usdBeta = cost.beta * usdPrices.beta

    cost._usdTOTAL = cost._usdKappa + cost._usdGamma + cost._usdRho + cost._usdBeta + cost._usdXi

    return cost;
};
