<template>
  <div class="full-wrapper">
    <router-link :to="{ name: 'claim' }">
      <div class="home" style="height:32px;width:32px;margin:16px;"></div>
    </router-link>

    <div class="oracle-card">
      <div class="row-centered">
        <img
          src="~/@/assets/xi_token_yellow.png"
          style="margin-bottom: 1rem;height:128px;padding: 1rem;
border-radius: 100%;
border: 1px solid #51cfb5;
box-shadow: 0px 0px 5px rgba(255, 179, 0, 0.44),inset 0px 0px 5px rgba(255, 179, 0, 0.63), rgb(28, 28, 28) 15px 15px 15px inset, rgb(51, 51, 51) -15px -15px 15px inset;"
          alt=""
          v-on:click="
            (slot1 = true), (slot2 = false), (slot3 = false), (slot4 = false), (slot5 = false)
          "
        />
      </div>

      <div id="slots">
        <div id="slot1 " class="slot " v-if="slot1">
          <h1 style="text-align:center;padding-top: 0rem!important;">ART UPLOAD WHITELIST</h1>
          <hr />

          <p style="margin-top:2rem;">
            In order to mitigate liability of the Developers, Geometric Space Corporation, And Space
            Exploration Technologies (SpaceX) we require users to complete a verification process
            attached to the wallet address intended for use with this platform. <br />
            <br />
            All content will be reviewed, and the users notified regarding rejection of uploaded
            content prior to display on space-fairing hardware operated by Geometric Space
            Corporation.
          </p>
          <div class="row-centered" style="margin:2rem auto;margin-bottom:unset!important;">
            <button
              style="display: flex;flex-flow: row;justify-content: space-between;"
              class="button"
              v-on:click="(slot2 = true), (slot1 = false)"
            >
              Connect Wallet
              <img
                src="~/@/assets/wallet.svg"
                style="width:24px;filter:invert(1);margin-left:1rem;"
                alt=""
              />
            </button>
          </div>
        </div>

        <div id="slot2" class="slot " v-if="slot2">
          <h1 style="text-align:center;padding-top: 0rem!important;">User Information</h1>
          <hr />
          <div class="stake-box">
            <div
              class=""
              style="display: flex;flex-flow: row;justify-content: space-between;margin:1rem auto;"
            >
              <p style="auto 0rem">Residence:</p>
              <input type="text" style="width:128px;" />
            </div>
            <div
              class=""
              style="display: flex;flex-flow: row;justify-content: space-between;margin:1rem auto;"
            >
              <p style="auto 0rem">Phone:</p>
              <vue-tel-input v-model="value"></vue-tel-input>
            </div>
            <div
              class=""
              style="display: flex;flex-flow: row;justify-content: space-between;margin:1rem auto;"
            >
              <p style="auto 0rem">Full Legal Name:</p>
              <input type="number" style="width:128px;" />
            </div>
            <div
              class=""
              style="display: flex;flex-flow: row;justify-content: space-between;margin:1rem auto;"
            >
              <p style="auto 0rem">Email:</p>
              <input type="email" style="width:128px;" />
            </div>
            <div
              class=""
              style="display: flex;flex-flow: row;justify-content: space-between;margin:1rem auto;"
            >
              <p style="auto 0rem">Wallet:</p>
              <p><b>0x346FA...3674</b></p>
            </div>
          </div>
          <div class="row-centered" style="margin:1rem auto;margin-bottom:0rem!important;">
            <button class="button" v-on:click="(slot3 = true), (slot2 = false)">Continue</button>
          </div>
        </div>

        <div id="slot4 " class="slot" v-if="slot3">
          <h1 style="text-align:center;padding-top: 0rem!important;">Restricted Countries</h1>

          <hr />

          <div class="stake-box">
            <p>
              Due to ITAR restrictions, users from the following countries are restricted from
              purchasing satcom hardware, connecting to satellites owned and operated by Geometric
              Space Corporation, and using the claiming interface present on this site.
            </p>
            <br />
            <p>
              Afghanistan, Belarus, Burma, Central African Republic, China (People's Republic),
              Cuba, Cyprus, Democratic Republic of the Congo, Eritrea, Haiti, Iran, Iraq,
              Kyrgyzstan, Lebanon, Libya, North Korea, Russia, Somalia, South Sudan, Sudan, Syria,
              Venezuela, and Zimbabwe;
            </p>
            <br />
            <p>
              By Clicking The button below, you are confirming residence outside of one of the prior
              mentioned ITAR restricted countries under legal penalty for false reporting.
            </p>
          </div>

          <div class="row-centered" style="margin:2rem auto;margin-bottom:0rem;">
            <button class="button" v-on:click="(slot4 = true), (slot3 = false)">
              Confirm Non-Residence
            </button>
          </div>
        </div>

        <div id="slot4 " class="slot" v-if="slot4">
          <h1 style="text-align:center;padding-top: 0rem!important;">Verify Contact Info</h1>

          <hr />
          <div class="stake-box">
            <div
              class=""
              style="display: flex;flex-flow: row;justify-content: space-between;margin:1rem auto;"
            >
              <p style="auto 0rem">Email:</p>
              <p><b>johndoe@gmail.com</b></p>
            </div>
            <div
              class=""
              style="display: flex;flex-flow: row;justify-content: space-between;margin:1rem auto;"
            >
              <p style="auto 0rem">Phone:</p>
              <p><b class="error">+93 XXX-XXXX</b></p>
            </div>
            <p class="error">The phone number provided is in an ITAR restricted country!</p>
          </div>

          <div class="row-centered" style="margin:2rem auto;margin-bottom:0rem;">
            <button class="button" v-on:click="(slot5 = true), (slot4 = false)">
              Send Verification
            </button>
          </div>
        </div>

        <div id="slot5 " class="slot" v-if="slot5">
          <h1 style="text-align:center;padding-top: 0rem!important;">Wallet Confirmation</h1>

          <hr />
          <div class="stake-box">
            <div
              class=""
              style="display: flex;flex-flow: row;justify-content: space-between;margin:1rem auto;"
            >
              <p style="auto 0rem">Wallet:</p>
              <p><b>0x346FA...3674</b></p>
            </div>
            <p class="error">The wallet provided doesnt contain any space-tokens!</p>
          </div>
          <div class="row-centered" style="margin:2rem auto; margin-bottom:unset;">
            <button class="button" v-on:click="(slot6 = true), (slot5 = false)">Approve</button>
          </div>
        </div>

        <div id="slot6 " class="slot" v-if="slot6">
          <h1 style="text-align:center;padding-top: 0rem!important;">SATCOM INTERFACE</h1>
          <hr />

          <p style="display: flex;flex-flow: row;justify-content: space-between;">
            Your Wallet: <b>0x346FA...3674</b>
          </p>
          <p>has been approved and added to the whitelist!</p>

          <div class="row-centered" style="margin:1rem auto; margin-bottom:unset;">
            <button class="button" v-on:click="(slot1 = true), (slot6 = false)">
              Approve Different Wallet
            </button>
          </div>
          <router-link :to="{ name: 'claim' }">
            <div class="row-centered" style="margin:1rem auto; margin-bottom:unset!important;">
              <button class="button" v-on:click="(slot1 = true), (slot6 = false)">
                Back to Claiming
              </button>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { VueTelInput } from 'vue-tel-input';

export default {
  data() {
    return {
      menu: [
        {
          title: 'wallet',
          icon: 'icon download'
          // component: FileInput
        },
        {
          title: 'stake',
          icon: 'icon rainbow'
        },
        {
          title: 'metaoracle',
          icon: 'icon spray'
        },
        {
          title: 'satcom',
          icon: 'icon check'
        },
        {
          //href: '/',
          title: 'arbiter',
          icon: 'icon save'
        }
      ],
      form: {
        quantity: ''
      },
      slot1: true,
      slot2: false,
      slot3: false,
      slot4: false,
      slot5: false,
      slot6: false,
      modalLoginOpen: false,
      modalMakepotionOpen: false
    };
  },
  components: {
    VueTelInput
  },
  methods: {
    onItemClick(event, item) {
      if (item.title === 'wallet') {
        this.downloadStage();
      }

      if (item.title === 'stake') {
        //location.href = "/#/pixel"
        this.pixelartEditor = !this.pixelartEditor;
      }

      if (item.title === 'metaoracle') {
        this.loadPixelArt();
      }

      if (item.title === 'satcom') {
        location.href = '/';
      }

      if (item.title === 'arbiter') {
        location.href = 'https://noclip.org';
      }
    },
    loadPixelArt() {
      const data = JSON.parse(localStorage.getItem('xi-pixelart'));
      if (data) {
        this.onLoad(data.url);
      }
    }
  }
};
</script>
<style scoped>
.hasEffect {
  cursor: pointer;
}
.data > p {
  min-height: 64px;
}
.row-centered {
  display: flex;
  flex-flow: row;
  justify-content: center;
}
.disabled {
  display: none !important;
}
#slots {
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: inset 5px 5px 5px #1c1c1c, inset -5px -5px 5px #333;
  border: 1px solid #51cfb52e;
}
.oracle-card {
  background-color: #2a2a2e;
  max-width: 512px;
  margin: auto;
  overflow: auto;
  padding: 1rem;
  min-height: 300px;
  border-radius: 1rem;
  min-width: 300px;
  transition: all 0.2s ease;
  border: 1px solid #51cfb52e;

  box-shadow: 5px 5px 5px #1c1c1c, -5px -5px 5px #333;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  background: unset !important;
}
@media screen and (max-width: 600px) {
  .oracle-card {
    margin: auto 1rem;
  }
}

.coords {
  border-top: 1px dotted #fff5;
}
.coords:nth-child(1) {
  border-top: unset !important;
}
b {
  color: #51cfb5;
}
button {
  width: 100%;
}
.row {
  border: 1px dotted #fff5;
  border-radius: 0.25rem;
}
.column {
  border-left: 1px dotted #fff5;
  text-align: center;
  padding: unset;
}
.column:nth-child(1) {
  border-left: unset !important;
}
h1 {
  font-size: 2rem !important;
  padding-top: unset !important;
}
</style>
