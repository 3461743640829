<template>
  <div class="full-wrapper">
    <router-link
      style="
    z-index: 2;"
      :to="{ name: 'claim' }"
    >
      <div class="home" style="height:32px;width:32px;margin:16px;"></div>
    </router-link>

    <div class="oracle-card">
      <div class="row-centered">
        <img
          src="~/@/assets/xi_token_yellow.png"
          style="margin-bottom: 1rem;height:128px;padding: 1rem;
border-radius: 100%;
border: 1px solid #51cfb5;
box-shadow: 0px 0px 5px rgba(255, 179, 0, 0.44),inset 0px 0px 5px rgba(255, 179, 0, 0.63), rgb(28, 28, 28) 15px 15px 15px inset, rgb(51, 51, 51) -15px -15px 15px inset;"
          alt=""
          v-on:click="
            (slot1 = true), (slot2 = false), (slot3 = false), (slot4 = false), (slot5 = false)
          "
        />
      </div>

      <div id="slots">
        <div id="slot1 " class="slot " v-if="slot1">
          <h1 style="text-align:center;padding-top: 0rem!important;">XI Oracle Interface</h1>
          <hr />

          <p style="margin-top:2rem;">
            The XI <b>Oracle</b> and <b>Metaoracle</b> features satcom connected sensors in orbit
            for metric prediction and collection along with reward for most accurate predictive
            model.
          </p>
          <div class="row-centered" style="margin:2rem auto;margin-bottom:unset;">
            <button
              style="display: flex;flex-flow: row;justify-content: space-between;"
              class="button"
              v-on:click="dispatchLogin()"
            >
              Connect Wallet
              <img
                src="~/@/assets/wallet.svg"
                style="width:24px;filter:invert(1);margin-left:1rem;"
                alt=""
              />
            </button>
          </div>
        </div>

        <div id="slot2" class="slot " v-if="slot2">
          <h1 style="text-align:center;padding-top: 0rem!important;">XI Staking</h1>
          <hr />
          <div class="stake-box">
            <hr />
            <div
              class=""
              style="display: flex;flex-flow: row;justify-content: space-between;margin:1rem auto;"
            >
              <p style="auto 0rem">Stake XI:</p>
              <input type="number" style="width:128px;" v-model="quantity" />
            </div>
            <hr />
          </div>
          <div class="row-centered" style="margin:1rem auto;margin-bottom:unset!important;">
            <button class="button" v-on:click="(slot3 = true), (slot2 = false)">Metaoracle</button>
          </div>
        </div>

        <div id="slot3 " class="slot" v-if="slot3">
          <h1 style="text-align:center;padding-top: 0rem!important;">Metaoracle</h1>
          <hr />

          <div class="data-box">
            <p style="display: flex;flex-flow: row;justify-content: space-between;">
              How Much Xi:<b>10</b>
            </p>
            <p style="display: flex;flex-flow: row;justify-content: space-between;">
              What Time:<b>23:00</b>
            </p>
            <p style="display: flex;flex-flow: row;justify-content: space-between;">
              Location:<b>45.0° , 23.4°</b>
            </p>
            <p style="display: flex;flex-flow: row;justify-content: space-between;">
              Physical Analytics:<b>T°</b>
            </p>
          </div>
          <hr />

          <p style="display: flex;flex-flow: row;justify-content: space-between;text-align:center;">
            XI Staked Multiplier <b>2.5X</b>
          </p>
          <div class="row-centered" style="margin:2rem auto;margin-bottom:0rem;">
            <button class="button" v-on:click="(slot4 = true), (slot3 = false)">
              Create Metaoracle
            </button>
          </div>
        </div>

        <div id="slot4 " class="slot" v-if="slot4">
          <h1 style="text-align:center;padding-top: 0rem!important;">Oracle Data</h1>

          <hr />

          <div class="row">
            <div class="column">
              <div class="">XI</div>
              <hr />
              <div class="data">
                <p>12</p>
                <p>11</p>
              </div>
            </div>

            <div class="column">
              <div class="">Time</div>
              <hr />
              <div class="data">
                <p>22:00</p>
                <p>23:00</p>
              </div>
            </div>

            <div class="column">
              <div class="">GPS</div>
              <hr />
              <div class="data">
                <p class="coords">45.2° 33.2°</p>
                <p class="coords">45.1° 33.5°</p>
              </div>
            </div>

            <div class="column">
              <div class="">T°</div>
              <hr />
              <div class="data">
                <p>54°</p>
                <p>52°</p>
              </div>
            </div>
          </div>
          <div class="row-centered" style="margin:2rem auto;margin-bottom:0rem;">
            <button class="button" v-on:click="(slot5 = true), (slot4 = false)">
              Connect To Satcom
            </button>
          </div>
        </div>

        <div id="slot5 " class="slot" v-if="slot5">
          <h1 style="text-align:center;padding-top: 0rem!important;">Arbiter</h1>
          <hr />

          <p style="display: flex;flex-flow: row;justify-content: space-between;">
            Country/Code:<b>10</b>
          </p>
          <p style="display: flex;flex-flow: row;justify-content: space-between;">
            Satcom ID:<b>10</b>
          </p>
          <p style="display: flex;flex-flow: row;justify-content: space-between;">
            Report:<b>732</b>
          </p>
          <p style="display: flex;flex-flow: row;justify-content: space-between;">
            T°/GPS/TIME:<b>52°/45.1° 33.5°/23:00</b>
          </p>
          <hr />

          <div class="row-centered" style="margin:2rem auto; margin-bottom:unset;">
            <button class="button" v-on:click="(slot6 = true), (slot5 = false)">Approve</button>
          </div>
        </div>

        <div id="slot6 " class="slot" v-if="slot6">
          <h1 style="text-align:center;padding-top: 0rem!important;">SATCOM INTERFACE</h1>
          <hr />

          <p style="display: flex;flex-flow: row;justify-content: space-between;">
            Connection Status:<b>CONNECTED</b>
          </p>
          <p style="display: flex;flex-flow: row;justify-content: space-between;">
            Satcom ID:<b>10</b>
          </p>
          <hr />

          <div class="row-centered" style="margin:2rem auto; margin-bottom:unset;">
            <button class="button" v-on:click="(slot1 = true), (slot6 = false)">RESTART</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { ethers } from 'ethers';

export default {
  data() {
    return {
      menu: [
        {
          title: 'wallet',
          icon: 'icon download'
          // component: FileInput
        },
        {
          title: 'stake',
          icon: 'icon rainbow'
        },
        {
          title: 'metaoracle',
          icon: 'icon spray'
        },
        {
          title: 'satcom',
          icon: 'icon check'
        },
        {
          //href: '/',
          title: 'arbiter',
          icon: 'icon save'
        }
      ],
      quantity: '0',
      slot1: true,
      slot2: false,
      slot3: false,
      slot4: false,
      slot5: false,
      slot6: false,
      modalLoginOpen: false,
      modalMakepotionOpen: false
    };
  },
  components: {},
  methods: {
    ...mapActions(['login', 'pull']),
    async dispatchLogin() {
      await this.login();
      this.slot2 = true;
      this.slot1 = false;
    },
    onItemClick(event, item) {
      if (item.title === 'wallet') {
        this.downloadStage();
      }

      if (item.title === 'stake') {
        //location.href = "/#/pixel"
        this.pixelartEditor = !this.pixelartEditor;
      }

      if (item.title === 'metaoracle') {
        this.loadPixelArt();
      }

      if (item.title === 'satcom') {
        location.href = '/';
      }

      if (item.title === 'arbiter') {
        location.href = 'https://noclip.org';
      }
    },
    loadPixelArt() {
      const data = JSON.parse(localStorage.getItem('xi-pixelart'));
      if (data) {
        this.onLoad(data.url);
      }
    }
  }
};
</script>
<style scoped>
.hasEffect {
  cursor: pointer;
}
.data > p {
  min-height: 64px;
}
.row-centered {
  display: flex;
  flex-flow: row;
  justify-content: center;
}
.disabled {
  display: none !important;
}
#slots {
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: inset 5px 5px 5px #0000001c, inset -5px -5px 5px #ffffff0a;
}
.oracle-card {
  background-color: #2a2a2e;
  max-width: 512px;
  margin: auto;
  padding: 1rem;
  min-height: 300px;
  border-radius: 1rem;
  min-width: 300px;
  transition: all 0.2s ease;
  border: 1px solid #51cfb52e;

  box-shadow: 5px 5px 5px #0000001c, -5px -5px 5px #ffffff0a;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  background: unset !important;
}
@media screen and (max-width: 600px) {
  .oracle-card {
    margin: auto 1rem;
  }
}

.coords {
  border-top: 1px dotted #fff5;
}
.coords:nth-child(1) {
  border-top: unset !important;
}
b {
  color: #51cfb5;
}
button {
  width: 100%;
}
.row {
  border: 1px dotted #fff5;
  border-radius: 0.25rem;
}
.column {
  border-left: 1px dotted #fff5;
  text-align: center;
  padding: unset;
}
.column:nth-child(1) {
  border-left: unset !important;
}
h1 {
  font-size: 2rem !important;
  padding-top: unset !important;
}
</style>
