<template>
  <Modal :open="open" @close="$emit('close')">
    <div class="modal">
        <div class="backdrop-close" v-on:click="$emit('close')"></div>      
        <div class="modal-wrapper satcom-card">
            <div class="modal-container">
                <div class="modal-header">
                    <img src="~/@/assets/xi.svg" style="width:128px;margin:auto;" alt="">
                </div>

                <div class="modal-body">
                    <div class="modal-box">

                        <h1 style="font-weight:500;">SATCOM ORDER</h1>
                        <br>

                        <form class="satcomform">
                            <div class="satcomrow" style="">
                                <div class="satcomrow-title">
                                    <p>COMPANY NAME <b style="color:yellow;">(Optional)</b>:</p>
                                </div>
                                <div class="satcomrow-input"><input style="padding:0.25rem;box-shadow:inset 2px 2px 5px #0000005e, inset -2px -2px 5px #ffffff0f;text-align:right;" type="text"></div>
                            </div>
                            <div class="satcomrow" style="">
                                <div class="satcomrow-title">
                                    <p>NAME <b style="color:yellow;">(First, Last)</b> <b v-if='checked === true && name== ""' style="color:red;">(Required)</b>:</p>
                                </div>
                                <div class="satcomrow-input"><input name='name' v-model='form.name' style="padding:0.25rem;box-shadow:inset 2px 2px 5px #0000005e, inset -2px -2px 5px #ffffff0f;text-align:right;" type="text"></div>
                            </div>
                            <div class="satcomrow" style="">
                                <div class="satcomrow-title">
                                    <p>PHONE NUMBER <b v-if='checked === true && phone== ""' style="color:red;">(Required)</b>:</p>
                                </div>
                                <div class="satcomrow-input"><input name='phone' v-model='form.phone' style="padding:0.25rem;box-shadow:inset 2px 2px 5px #0000005e, inset -2px -2px 5px #ffffff0f;text-align:right;" type="tel"></div>
                            </div>
                            <br>
                            <p style="text-align:left; margin:4px;"><b class="aqua" >MAILING ADDRESS</b></p><br>

                            <div class="satcomrow" style="">
                                <div class="satcomrow-title">
                                    <p>STREET ADDRESS <b v-if='checked === true && street== ""' style="color:red;">(Required)</b>:</p>
                                </div>
                                <div class="satcomrow-input"><input name='street' v-model='form.street' style="padding:0.25rem;box-shadow:inset 2px 2px 5px #0000005e, inset -2px -2px 5px #ffffff0f;text-align:right;" type="text"></div>
                            </div>
                            <div class="satcomrow" style="">
                                <div class="satcomrow-title">
                                    <p>APT. / UNIT NUMBER:<b style="color:yellow;">(Optional)</b></p>
                                </div>
                                <div class="satcomrow-input"><input name='aptNo' v-model='form.aptNo' style="padding:0.25rem;box-shadow:inset 2px 2px 5px #0000005e, inset -2px -2px 5px #ffffff0f;text-align:right;" type="text"></div>
                            </div>

                            <div class="satcomrow" style="">
                                <div class="satcomrow-title">
                                    <p>CITY <b v-if='checked === true && city== ""' style="color:red;">(Required)</b>:</p>
                                </div>
                                <div class="satcomrow-input"><input name='city' v-model='form.city' style="padding:0.25rem;box-shadow:inset 2px 2px 5px #0000005e, inset -2px -2px 5px #ffffff0f;text-align:right;" type="text"></div>
                            </div>
                            <div class="satcomrow" style="">
                                <div class="satcomrow-title">
                                    <p>STATE / PROVINCE <b v-if='checked === true && state == ""' style="color:red;">(Required)</b>:</p>
                                </div>
                                <div class="satcomrow-input"><input name='state' v-model='form.state' style="padding:0.25rem;box-shadow:inset 2px 2px 5px #0000005e, inset -2px -2px 5px #ffffff0f;text-align:right;" type="text"></div>
                            </div>
                            <div class="satcomrow" style="">
                                <div class="satcomrow-title">
                                    <p>ZIP CODE <b v-if='checked === true && zip == ""' style="color:red;">(Required)</b>:</p>
                                </div>
                                <div class="satcomrow-input"><input name='zipcode' v-model='form.zip' style="padding:0.25rem;box-shadow:inset 2px 2px 5px #0000005e, inset -2px -2px 5px #ffffff0f;text-align:right;" type="text"></div>
                            </div>

                            <br>
                            <div class="satcomrow" style="">
                                <div class="satcomrow-title">
                                    <p>Country <b v-if='checked === true && country== ""' style="color:red;">(Required)</b>:</p>
                                </div>
                                <div class="satcomrow-input">
                                    <select v-model='form.country' style="  padding: 0.25rem;
  box-shadow: rgba(0, 0, 0, 0.37) 2px 2px 5px inset, rgba(255, 255, 255, 0.06) -2px -2px 5px inset;
  text-align: right;
  color: aqua !important;
  background-color: #2a2a2e !important;
  border-radius: 0.25rem;
  border: unset;
  transition: all 0.2s ease;" name='country'>
                                        <option v-for='country in countries' :key='country' :value='country'>
                                            {{country}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <br />                            
                            <div class="satcomrow" style="">
                                <div class="satcomrow-title">
                                    <p>EMAIL <b v-if='checked === true && email == ""' style="color:red;">(Required)</b>:</p>
                                </div>
                                <div class="satcomrow-input"><input name='email' v-model='form.email' style="padding:0.25rem;box-shadow:inset 2px 2px 5px #0000005e, inset -2px -2px 5px #ffffff0f;text-align:right;" type="email"></div>
                            </div>



                        </form>

                    </div>
                    <div class="row" style="margin: 1rem auto; margin-bottom: 1rem">
                        <button class="button" style="background-color:#7a7a7b!important;max-width:300px;margin: auto;" v-on:click="submit">
                            CONFIRM
                        </button>
                    </div>
                </div>
            </div>
        </div>
      </div>
  </Modal>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import countries from '@/helpers/countries.json';

export default {
  props: ['open', 'requiresLogin'],
  data() {
    return {
      isLoading: false,
      checked: false,
      service: '',
      countries: countries,
      form: {
                    name: '',
                    phone: '',
                    street: '',
                    aptNo: '',
                    city: '',
                    state: '',
                    zip: '',
                    country: '',
                    email: '',
               }
    };
  },
  watch: {
  },
  methods: {
      ...mapActions(['login']),
      ...mapMutations(['setOrbcommAddress']),
      valid() {
          if(this.form.name == '' ||
             this.form.phone == '' ||
             this.form.street == '' ||
             this.form.city == '' ||
             this.form.state == '' ||
             this.form.zip == '' ||
             this.form.country == '' ||
             this.form.email == '')
             {   this.checked = true;
                 return false;
             }
            return true;
      },
      submit() {
          if(this.valid()) {
              this.setOrbcommAddress({address:this.form});
              this.$emit('chain', {showSatcomModal: false, showSatcomPaymentModal: true});
          }
      }
  }
};
</script>
