<template>
    <div :height="windowHeight + 'px' + '!important'" style="
                                                            height:100vh;
                                                              overflow: auto;
                                                              display: flex;
                                                              flex-flow: column;
                                                              justify-content: flex-start;
                                                            ">
        <div class="appcolumn appcolumn-parent ">
            <div class="titlebox approw flex-start-between">
                <div class="place-title">
                    <h1>LUNAR & EARTH <br />ORBIT DISPLAYS</h1>
                    <div class="nodesktop" style="">
                        <b style="margin: auto;margin-top:unset!important;color:yellow;font-size:0.45rem!important;  margin-left: 0rem;">{{ $store.state.settings.address }}</b
                                            >
                                          </div>
                                        </div>
                                        <div id="preview" />
                                      </div>
                                
                                      <div class="approw approw-col" style="">
                                        <div class="appcolumn">
                                          <div class="canvas-slider">
                                            <div class="approw">
                                              <div class="rowmobile">
                                                <div class="menuspace">
                                                  <vue-base64-file-upload
                                                    accept="image/png,image/jpeg"
                                                    input-class="fileinput hidden appcol-row"
                                                    :disable-preview="true"
                                                    :max-size="customImageMaxSize"
                                                    aria-placeholder=""
                                                    placeholder=""
                                                    ref="fileinput"
                                                    @size-exceeded="onSizeExceeded"
                                                    @load="onLoad"
                                                  />
                                
                                                  <div class="canvas-buttons">
                                                    <div
                                                      v-if="$store.state.settings.address"
                                                      class="canvas-button"
                                                      v-on:click="showStakingModal = true"
                                                    >
                                                      <p style="padding-left:14px;" class=" aq">STAKING</p>
                                
                                                      <img src="~/@/assets/discover.svg" width="32px" class="greekicon nm" alt="" />
                                                      <img
                                                        src="~/@/assets/discoveraqua.svg"
                                                        width="32px"
                                                        class="greekicon aq"
                                                        alt=""
                                                      />
                                                    </div>
                                
                                                    <div class="canvas-button" v-on:click="upload()">
                                                      <p style="padding-left:16px;" class=" aq">UPLOAD</p>
                                
                                                      <img
                                                        style=""
                                                        src="~/@/assets/image.svg"
                                                        width="32px"
                                                        class="greekicon nm"
                                                        alt=""
                                                      />
                                                      <img
                                                        style=""
                                                        src="~/@/assets/imageaqua.svg"
                                                        width="32px"
                                                        class="greekicon aq"
                                                        alt=""
                                                      />
                                                    </div>
                                
                                                    <button
                                                      v-if="!$store.state.settings.isMM"
                                                      class="button connecttop"
                                                      style=""
                                                      v-on:click="dispatchLogin"
                                                    >
                                                      Metamask required
                                                    </button>
                                
                                                    <button
                                                      v-else-if="
                                                        $store.state.settings.contractBooking[$store.state.settings.network]
                                                      "
                                                      class="button connecttop"
                                                      style="  "
                                                      v-on:click="dispatchLogin"
                                                    >
                                                      {{
                                                        $store.state.settings.address ? this.getPrettyWallet() : 'Connect Wallet'
                                                      }}
                                                    </button>
                                                    <button v-else class="button connecttop" style="">
                                                      Unsupported Network
                                                    </button>
                                
                                                    <button
                                                      v-if="
                                                        $store.state.settings.address &&
                                                          $store.state.settings.admins.includes($store.state.settings.address)
                                                      "
                                                      class="button connecttop"
                                                      style=""
                                                      v-on:click="dispatchOrders"
                                                    >
                                                      ORDERS
                                                    </button>
                                                    <a href="/#/">
                                                      <div class="canvas-button">
                                                        <p style="padding-left:20px;" class=" aq">HOME</p>
                                
                                                        <img
                                                          style=""
                                                          src="~/@/assets/earth.svg"
                                                          width="32px"
                                                          class="greekicon nm"
                                                          alt=""
                                                        />
                                                        <img
                                                          style=""
                                                          src="~/@/assets/earthaqua.svg"
                                                          width="32px"
                                                          class="greekicon aq"
                                                          alt=""
                                                        />
                                                      </div>
                                                    </a>
                                                  </div>
                                                </div>
                                                <div class="greekrow2">
                                                  <p class="nomobile tooltipgreeks">
                                                    CLICK TO BUY
                                                    <img
                                                      src="~/@/assets/down1.svg"
                                                      style="transform:rotate(-90deg);margin:auto;margin-left:0.5rem;"
                                                      width="32px"
                                                      height="32px"
                                                    />
                                                  </p>
                                                  <a
                                                    target="_blank"
                                                    href="https://app.uniswap.org/#/swap?outputCurrency=0x3f3cd642e81d030d7b514a2ab5e3a5536beb90ec&chain=mainnet"
                                                  >
                                                    <div class="greeknamewrap">
                                                      <p>
                                                        RHO <br />
                                                        Y-AXIS
                                                      </p>
                                                      <img
                                                        src="~/@/assets/rho.svg"
                                                        width="64px"
                                                        class="greekicon nm"
                                                        @mouseover="(rhodata = true), (rhodata2 = false)"
                                                        @mouseleave="(rhodata = false), (rhodata2 = true)"
                                                        alt=""
                                                      />
                                
                                                      <img
                                                        src="~/@/assets/rhoaqua.svg"
                                                        width="64px"
                                                        class="greekicon aq"
                                                        @mouseover="(rhodata = true), (rhodata2 = false)"
                                                        @mouseleave="(rhodata = false), (rhodata2 = true)"
                                                        alt=""
                                                      />
                                                    </div>
                                                  </a>
                                                  <a
                                                    target="_blank"
                                                    href="https://app.uniswap.org/#/swap?outputCurrency=0x35f67c1d929e106fdff8d1a55226afe15c34dbe2&chain=mainnet"
                                                  >
                                                    <div class="greeknamewrap">
                                                      <p>
                                                        BETA <br />
                                                        X-AXIS
                                                      </p>
                                                      <img
                                                        src="~/@/assets/beta.svg"
                                                        width="64px"
                                                        class="greekicon nm"
                                                        @mouseover="(betadata = true), (betadata2 = false)"
                                                        @mouseleave="(betadata = false), (betadata2 = true)"
                                                        alt=""
                                                      />
                                
                                                      <img
                                                        src="~/@/assets/betaaqua.svg"
                                                        width="64px"
                                                        class="greekicon aq"
                                                        @mouseover="(betadata = true), (betadata2 = false)"
                                                        @mouseleave="(betadata = false), (betadata2 = true)"
                                                        alt=""
                                                      />
                                                    </div>
                                                  </a>
                                                  <a
                                                    target="_blank"
                                                    href="https://app.uniswap.org/#/swap?outputCurrency=0x1e1eed62f8d82ecfd8230b8d283d5b5c1ba81b55&chain=mainnet"
                                                  >
                                                    <div class="greeknamewrap">
                                                      <p>
                                                        GAMMA <br />
                                                        BRIGHTNESS
                                                      </p>
                                                      <img
                                                        src="~/@/assets/gamma.svg"
                                                        width="64px"
                                                        class="greekicon nm"
                                                        @mouseover="(gammadata = true), (gammadata2 = false)"
                                                        @mouseleave="(gammadata = false), (gammadata2 = true)"
                                                        alt=""
                                                      />
                                                      <img
                                                        src="~/@/assets/gammaaqua.svg"
                                                        width="64px"
                                                        class="greekicon aq"
                                                        @mouseover="(gammadata = true), (gammadata2 = false)"
                                                        @mouseleave="(gammadata = false), (gammadata2 = true)"
                                                        alt=""
                                                      />
                                                    </div>
                                                  </a>
                                                  <a
                                                    target="_blank"
                                                    href="https://app.uniswap.org/#/swap?outputCurrency=0x5D2C6545d16e3f927a25b4567E39e2cf5076BeF4&chain=mainnet"
                                                  >
                                                    <div class="greeknamewrap">
                                                      <p>
                                                        KAPPA <br />
                                                        HUE
                                                      </p>
                                                      <img
                                                        src="~/@/assets/kappa.svg"
                                                        width="64px"
                                                        class="greekicon nm"
                                                        @mouseover="(kappadata = true), (kappadata2 = false)"
                                                        @mouseleave="(kappadata = false), (kappadata2 = true)"
                                                        alt=""
                                                      />
                                
                                                      <img
                                                        src="~/@/assets/kappaaqua.svg"
                                                        width="64px"
                                                        class="greekicon aq"
                                                        @mouseover="(kappadata = true), (kappadata2 = false)"
                                                        @mouseleave="(kappadata = false), (kappadata2 = true)"
                                                        alt=""
                                                      />
                                                    </div>
                                                  </a>
                                                  <a
                                                    target="_blank"
                                                    href="https://app.uniswap.org/#/swap?outputCurrency=0x295B42684F90c77DA7ea46336001010F2791Ec8c&chain=mainnet"
                                                  >
                                                    <div class="greeknamewrap">
                                                      <p>
                                                        XI <br />
                                                        TIME
                                                      </p>
                                                      <img
                                                        src="~/@/assets/xi.svg"
                                                        width="64px"
                                                        class="greekicon nm xigreek"
                                                        @mouseover="(xidata = true), (xidata2 = false)"
                                                        @mouseleave="(xidata = false), (xidata2 = true)"
                                                        alt=""
                                                      />
                                
                                                      <img
                                                        src="~/@/assets/xiaqua.svg"
                                                        width="64px"
                                                        class="greekicon aq  xigreek"
                                                        @mouseover="(xidata = true), (xidata2 = false)"
                                                        @mouseleave="(xidata = false), (xidata2 = true)"
                                                        alt=""
                                                      />
                                                    </div>
                                                  </a>
                                                </div>
                                              </div>
                                            </div>
                                            <v-stage
                                              v-dragscroll="drag"
                                              class="containerstage"
                                              id="containerstage"
                                              ref="stage"
                                              :config="configKonva"
                                              @dragend="handleDragend"
                                              @dragover="handleDragover"
                                              @dragStart="handleDragstart"
                                            >
                                              <!--v-layer ref="layer"></v-layer-->
                                            </v-stage>
                                
                                            <div class="nomobile betarho" style="">
                                              <h2
                                                v-show="betadata2"
                                                style="  line-height: 1;
                                                          margin-left: 2rem;
                                                          margin-top: 1.2rem;
                                                          font-weight: 200;
                                                          font-size: 1rem !important;"
                                              >
                                                BETA[X]
                                              </h2>
                                              <h2
                                                v-show="betadata"
                                                style="  line-height: 1;
                                                          margin-left: 2rem;
                                                          margin-top: 1.2rem;
                                                          font-weight: 200;
                                                          font-size: 1rem !important;color:yellow!important;"
                                              >
                                                BETA[X]
                                              </h2>
                                              <h2
                                                v-show="rhodata2"
                                                style="  margin-left: -6.75rem;
                                                          font-weight: 200;
                                                          margin-top: -73px;
                                                          text-align: center;
                                                          font-size: 1rem !important;
                                                          line-height: 1;"
                                              >
                                                R<br />H<br />O<br />[Y]
                                              </h2>
                                              <h2
                                                v-show="rhodata"
                                                style="  margin-left: -6.75rem;
                                                          font-weight: 200;
                                                          margin-top: -73px;
                                                          text-align: center;
                                                          font-size: 1rem !important;
                                                          line-height: 1;color:yellow!important;"
                                              >
                                                R<br />H<br />O<br />[Y]
                                              </h2>
                                            </div>
                                          </div>
                                        </div>
                                
                                        <div class="appcolumn data-column" style="">
                                          <div class="greekdata">
                                            <div class="dragtool" @click="drag = !drag">
                                              <b v-if="drag">DRAGING</b>
                        <b v-if="!drag">SCROLLING</b>
                    </div>
                </div>
    
                <!-- <div class="greekbuy">
                                                        
                                                                            <a v-if="getFormattedRhoBalance() <= getFormattedRhoPrice()" target="_blank" href="https://app.uniswap.org/#/swap?outputCurrency=0x3F3Cd642E81d030D7b514a2aB5e3a5536bEb90Ec&chain=mainnet"><h2>NEED {{getFormattedRhoPrice() - getFormattedRhoBalance()}} MORE RHO <img src="~/@/assets/exit-top-right.svg" style="  width: 12px;margin-bottom: -2px;margin-left: auto;" alt=""></h2></a>
                                                                            <a v-if="getFormattedBetaBalance() <= getFormattedBetaPrice()" target="_blank" href="https://app.uniswap.org/#/swap?outputCurrency=0x35F67c1D929E106FDfF8D1A55226AFe15c34dbE2&chain=mainnet"><h2>NEED {{getFormattedBetaPrice() - getFormattedBetaBalance()}} MORE BETA <img src="~/@/assets/exit-top-right.svg" style="  width: 12px;margin-bottom: -2px;margin-left: auto;" alt=""></h2></a>
                                                                            <a v-if="getFormattedGammaBalance() <= getFormattedGammaPrice()" target="_blank" href="https://app.uniswap.org/#/swap?outputCurrency=0x1E1EEd62F8D82ecFd8230B8d283D5b5c1bA81B55&chain=mainnet"><h2>NEED {{getFormattedGammaPrice() - getFormattedGammaBalance()}} MORE GAMMA <img src="~/@/assets/exit-top-right.svg" style="  width: 12px;margin-bottom: -2px;margin-left: auto;" alt=""></h2></a>
                                                                            <a v-if="getFormattedKappaBalance() <= getFormattedKappaPrice()" target="_blank" href="https://app.uniswap.org/#/swap?outputCurrency=0x3F3Cd642E81d030D7b514a2aB5e3a5536bEb90Ec&chain=mainnet"><h2>NEED {{getFormattedKappaPrice() - getFormattedKappaBalance()}} MORE KAPPA <img src="~/@/assets/exit-top-right.svg" style="  width: 12px;margin-bottom: -2px;margin-left: auto;" alt=""></h2></a>
                                                                            <a v-if="getFormattedXiBalance() <= getFormattedXiPrice()" target="_blank" href="https://app.uniswap.org/#/swap?outputCurrency=0x295B42684F90c77DA7ea46336001010F2791Ec8c&chain=mainnet"><h2>NEED {{getFormattedXiPrice() - getFormattedXiBalance()}} MORE XI <img src="~/@/assets/exit-top-right.svg" style="  width: 12px;margin-bottom: -2px;margin-left: auto;" alt=""></h2></a>
                                                                            
                                                                            <p v-else style="margin-top: unset; margin-bottom: unset; font-size: 0.75rem !important;">ENOUGH TOKENS TO PURCHASE<img src="~/@/assets/walletlight.svg" style="  width: 12px;margin-bottom: -2px;margin-left: 4px;" alt=""></p>
                                                        
                                                                        </div>
                                                        -->
                <div class="greekdata">
                    <p style="margin-top: unset; margin-bottom: 0.5rem; font-size: 0.75rem !important;">
                        LIVE IMAGE QUOTE
                    </p>
                    <div class="rhodatabox">
                        <h2 style="font-weight:300;display:flex;flex-flow:row;justify-content:space-between" v-show="rhodata2">
                            RHO <b style="color:yellow;">{{ getFormattedRhoPrice() }}</b>
                        </h2>
                        <h2 style="font-weight:300;display:flex;flex-flow:row;justify-content:space-between" v-show="rhodata">
                            RHO <b style="color:aqua;">{{ getFormattedRhoBalance() }}</b>
                        </h2>
                    </div>
                    <div class="betadatabox">
                        <h2 style="font-weight:300;display:flex;flex-flow:row;justify-content:space-between" v-show="betadata2">
                            BETA <b style="color:yellow;">{{ getFormattedBetaPrice() }}</b>
                        </h2>
                        <h2 style="font-weight:300;display:flex;flex-flow:row;justify-content:space-between" v-show="betadata">
                            BETA <b style="color:aqua;">{{ getFormattedBetaBalance() }}</b>
                        </h2>
                    </div>
                    <div class="gammadatabox">
                        <h2 style="font-weight:300;display:flex;flex-flow:row;justify-content:space-between" v-show="gammadata2">
                            GAMMA <b style="color:yellow;">{{ getFormattedGammaPrice() }}</b>
                        </h2>
                        <h2 style="font-weight:300;display:flex;flex-flow:row;justify-content:space-between" v-show="gammadata">
                            GAMMA <b style="color:aqua;">{{ getFormattedGammaBalance() }}</b>
                        </h2>
                    </div>
                    <div class="kappadatabox">
                        <h2 style="font-weight:300;display:flex;flex-flow:row;justify-content:space-between" v-show="kappadata2">
                            KAPPA <b style="color:yellow;">{{ getFormattedKappaPrice() }}</b>
                        </h2>
                        <h2 style="font-weight:300;display:flex;flex-flow:row;justify-content:space-between" v-show="kappadata">
                            KAPPA <b style="color:aqua;">{{ getFormattedKappaBalance() }}</b>
                        </h2>
                    </div>
                    <div class="xidatabox">
                        <h2 style="font-weight:300;display:flex;flex-flow:row;justify-content:space-between" v-show="xidata2">
                            XI <b style="color:yellow;">{{ getFormattedXiPrice() }}</b>
                        </h2>
                        <h2 style="font-weight:300;display:flex;flex-flow:row;justify-content:space-between" v-show="xidata">
                            XI <b style="color:aqua;">{{ getFormattedXiBalance() }}</b>
                        </h2>
                    </div>
                </div>
    
                <div class="scrollercontainer">
                    <!--
                                                        <div class="calendar-box">
                                                        
                                                        <div class="calendar"><img style="width:30px;height:30px;filter:invert(0.9);" src="~/@/assets/calendar.svg" alt=""></div>
                                                        <div class="interval-label">
                                                        <div class="interval-icon"></div></div>
                                                        </div>
                                                        
                                                        -->
    
                    <div class="date-picker-wrap" style="">
                        <v-date-picker v-model="range" color="teal" :min-date="new Date(2022, 9, 1)" mode="dateTime" timezone="UTC" is-dark is-range>
                        </v-date-picker>
                    </div>
    
                    <div class="button" style="  margin-top: 1rem" v-on:click="startSpaceSwap" v-if="
                                                $store.state.settings.address &&
                                                  this.myPlacement &&
                                                  !this.hasCollision &&
                                                  this.finalPrice.xi >= 0
                                              ">
                        Spaceswap ERC20
                    </div>
                    <div class="button" style="  margin-top: 1rem" v-on:click="showContentGuidelines = true" v-if="
                                                $store.state.settings.address &&
                                                  this.myPlacement &&
                                                  !this.hasCollision &&
                                                  this.finalPrice.xi !=
                                                    0 /* && this.getFormattedBetaBalance() >=  this.getFormattedBetaPrice() && this.getFormattedKappaBalance() >= this.getFormattedKappaPrice() && this.getFormattedRhoBalance() >= this.getFormattedRhoPrice() && this.getFormattedGammaBalance() >= this.getFormattedGammaPrice() && this.getFormattedXiBalance() >= this.getFormattedXiPrice() */
                                              ">
                        Send To Space
                    </div>
                    <div class="button" style="background: yellow; margin-top: 1rem" disabled v-if="$store.state.settings.address && this.myPlacement && this.hasCollision">
                        Resolve Editor Collision Conflicts
                    </div>
                    <div class="button" style="background: yellow; margin-top: 1rem" disabled v-if="$store.state.settings.address && this.myPlacement && this.finalPrice.xi == 0">
                        Time Range too Short
                    </div>
                    <div class="transaction-tracker" style="opacity:0.5;">
                        <ul class="txtracklist" v-if="$store.state.settings.address" id="example-1">
                            <li style="text-transform:uppercase;line-height: 1rem;display:flex;flex-flow:row;justify-content:space-between;font-size:0.5rem;" v-for="key in $store.state.settings.pendingTxns.values()" :key="key.hash">
                                TX STATUS<a :href="getEtherscanUrl(key)">{{ getShortHash(key.hash) }}</a> {{ key.confirmations > 0 ? 'confirmed' : key.confirmations == 0 ? 'pending' : 'failed' }}
                            </li>
                        </ul>
                    </div>
    
                    <!--
                                                         <HistogramSlider
                                                              style="margin: 200px auto"
                                                              :width="800"
                                                              :bar-height="64"
                                                              :data="data"
                                                              :prettify="prettify"
                                                              :drag-interval="true"
                                                              :force-edges="false"
                                                              :colors="['blue', 'red']"
                                                              :min="new Date(2022, 11, 24).valueOf()"
                                                              :max="new Date(2025, 11, 24).valueOf()"
                                                            />
                                                                  <DynamicScroller :items="list" :min-item-size="54" direction="horizontal" class="scroller" >
                                                                    <template v-slot="{ item, index, active }">
                                                                      <DynamicScrollerItem
                                                                        :item="item"
                                                                        :active="active"
                                                                        :data-index="index"
                                                                        :data-active="active"
                                                                        :title="`Click to change message ${index}`"
                                                                        :style="{
                                                                          width: `100px`
                                                                        }"
                                                                        class="message"
                                                                        @click.native="onTimelineClick(item)"
                                                                      >
                                                                        <button v-if="item.id === selectedPeriod" class="timebutton selectedtime">
                                                                          Week {{ item.id }}
                                                                        </button>
                                                                        <button v-else class="timebutton">
                                                                          Week {{ item.id }}
                                                                        </button>
                                                                      </DynamicScrollerItem>
</template>
          </DynamicScroller>
-->
          </div>
        </div>
      </div>
    </div>

    <!-- Staking -->

    <StakingModal
      :open="showStakingModal"
      v-on:close="showStakingModal = false"
      v-on:chain="chainModals"
    >
    </StakingModal>

    <StakingConfirmModal
      :open="showStakingConfirmModal"
      v-on:close="showStakingConfirmModal = false"
      v-on:chain="chainModals"
    >
    </StakingConfirmModal>

    <!-- Satcom Ordering -->

    <SatcomModal
      :open="showSatcomModal"
      v-on:close="showSatcomModal = false"
      v-on:chain="chainModals"
    >
    </SatcomModal>

    <SatcomRejectedModal
      :open="showSatcomRejectedModal"
      v-on:close="showSatcomRejectedModal = false"
      v-on:chain="chainModals"
    >
    </SatcomRejectedModal>

    <SatcomPaymentModal
      :open="showSatcomPaymentModal"
      v-on:close="showSatcomPaymentModal = false"
      v-on:chain="chainModals"
    >
    </SatcomPaymentModal>

    <SatcomPaymentConfirmedModal
      :open="showSatcomPaymentConfirmedModal"
      v-on:close="showSatcomPaymentConfirmedModal = false"
      v-on:chain="chainModals"
    >
    </SatcomPaymentConfirmedModal>

    <SatcomOrdersModal
      :open="showSatcomOrdersModal"
      v-on:close="showSatcomOrdersModal = false"
      v-on:chain="chainModals"
    >
    </SatcomOrdersModal>
    <SpaceswapConfirm
      ref="spaceswap"
      :open="showSpaceswapConfirm"
      :slippage="slippage"
      v-on:close="closeSpaceswapConfirm()"
    >
    </SpaceswapConfirm>
    <!-- Moderation -->
    <div class="modal" v-show="showModerationPendingModal">
      <div class="backdrop-close" v-on:click="showModerationPendingModal = false"></div>

      <div class="modal-wrapper oracle-card">
        <div class="modal-container">
          <div class="modal-header">
            <img src="~/@/assets/xi.svg" style="width:128px;margin:auto;" alt="" />
          </div>

          <div class="modal-body">
            <div class="modal-box">
              <h1 style="font-weight:500;text-align:center;">MODERATION</h1>
              <br />
              <h3 style="color:yellow;text-align:center;">THANK YOU!</h3>
              <p style="text-align:center;">
                YOUR TIME IN SPACE WAS SUBMITTED FOR MODERATION
                <b
                  style="
                          border-radius: 100%;
                          color:#111;text-align:center;
                          background:yellow;
                          border: 1px solid yellow;
                          font-size: 0.75rem;
                          font-weight:800;
                          padding: 0rem 0.3rem;
                        "
                  >i</b
                >
              </p>
              <br />
            </div>
            <div class="row" style="margin: 1rem auto; margin-bottom: 1rem">
              <button
                class="button"
                style="background-color:#7a7a7b!important;max-width:300px;margin: auto;"
                v-on:click="showModerationPendingModal = false"
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal" v-show="showModerationApprovedModal">
      <div class="backdrop-close" v-on:click="showModerationApprovedModal = false"></div>

      <div class="modal-wrapper oracle-card">
        <div class="modal-container">
          <div class="modal-header">
            <img src="~/@/assets/xi.svg" style="width:128px;margin:auto;" alt="" />
          </div>

          <div class="modal-body">
            <div class="modal-box">
              <h1 style="font-weight:500;text-align:center;">MODERATION</h1>
              <br />
              <p style="">
                <b style="color:yellow;text-align:center;">SUCCESS!</b>
                YOUR ART WAS SUCCESSFULLY REVIEWED AND APPROVED BY OUR ART MODERATION DEPARTMENT.
                YOUR ART WILL NOW BE UPLOADED TO THE SPACE TIME DISPLAY ON THE DATE AND TIME SHOWN
                ON YOUR RECEIPT.
                <b
                  style="
                          border-radius: 100%;
                          color:#111;text-align:center;
                          background:yellow;
                          border: 1px solid yellow;
                          font-size: 0.75rem;
                          font-weight:800;
                          padding: 0rem 0.3rem;
                        "
                  >i</b
                >
              </p>
              <br />
            </div>
            <div class="row" style="margin: 1rem auto; margin-bottom: 1rem">
              <button
                class="button"
                style="background-color:#7a7a7b!important;max-width:300px;margin: auto;"
                v-on:click="
                  (showModerationApprovedModal = false), (showModerationRejectedModal = true)
                "
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal" v-show="showSwap">
      <div class="backdrop-close" v-on:click="showSwap = false"></div>

      <div
        class="error-modal-wrapper"
        style="width: 512px;background: #232323!important;border-radius:15px;padding:2rem; text-align:center;box-shadow: 0px 10px 100px black;"
      >
        <Hex style="margin: auto;" v-if="pricesLoading === true" />
        <div class="" v-if="pricesLoading === false" style="text-align:center;">
          <h1 style="text-align:center;font-weight:900!important;">SPACESWAP</h1>
        
          <p
            style=" margin:auto;padding-bottom:2rem; width: 450px;
  max-width: 100%;
  font-weight: 300;
  margin: 0rem auto ;
  font-size: 0.75rem !important;
  padding-top: 1rem;"
          >
            Spaceswap makes it easy to acquire all needed spacetokens in fewer steps. Just select
            the desired ERC20 token to convert and your content will be broadcasted to orbit in no
            time!
          </p>
          <div style="border-radius: 15px;
border: 1px solid hsla(0,0%,100%,.15);padding:0.5rem 1rem;background-color: rgba(0,0,0,.2);">
          <p class="spaceswap-price">
            XI USDC Price: <b>${{ getFormattedTokenPrice(tokenPrices.xi) }}</b>
          </p>
          <p class="spaceswap-price">
            BETA USDC Price: <b>${{ getFormattedTokenPrice(tokenPrices.beta) }}</b>
          </p>
          <p class="spaceswap-price">
            GAMMA USDC Price: <b>${{ getFormattedTokenPrice(tokenPrices.gamma) }}</b>
          </p>
          <p class="spaceswap-price">
            KAPPA USDC Price: <b>${{ getFormattedTokenPrice(tokenPrices.kappa) }}</b>
          </p>
          <p class="spaceswap-price">
            RHO USDC Price: <b>${{ getFormattedTokenPrice(tokenPrices.rho) }}</b>
          </p>
          <p class="spaceswap-price">
            Total: <b>${{ getFormattedTotal }}</b>
          </p>
          </div>
          <div class="swaprow">
            <div>
              <label for="token">Token</label>
              <input
                id="token"
                v-if="spaceSwapReady"
                style=""
                type="text"
                placeholder="USDC"
                name="ERC20"
                list="erc20"
                v-model="token"
              />
            </div>
            <div>
              <label for="slippage">Slippage %</label>
              <input
                id="slippage"
                v-if="spaceSwapReady"
                style=""
                type="number"
                placeholder="2%"
                v-model="slippage"
              />
            </div>
          </div>
          <datalist id="erc20">
            <option value="USDC" selected>USDC</option>
          </datalist>
        </div>
        <div class="row" style="margin: 0.5rem auto; margin-bottom: 0rem">
          <button
            v-if="pricesLoading === false"
            class="button"
            style="  
  padding: .5rem 5rem;
  background-color: transparent;
  border: 1px solid #fff!important;
  color: #fff;
  border-radius: 15px;margin-bottom:unset!important;
  width: 100%;
"
            v-on:click="
              spaceSwapping = true;
              spaceSwapReady = false;
              showSpaceswapConfirm = true;
              showSwap = false;
            "
          >
            Swap
          </button>
        </div>
      </div>
    </div>
    <div class="modal" v-show="showModerationRejectedModal">
      <div class="backdrop-close" v-on:click="showModerationRejectedModal = false"></div>

      <div
        class="error-modal-wrapper"
        style="max-width:400px;color:red!important;background-color:yellow!important;padding:2rem;"
      >
        <div class="">
          <div class="row">
            <img src="~/@/assets/warning.svg" style="margin:auto;width:128px;margin:auto;" alt="" />
          </div>

          <h1 style="text-align:center;font-weight:900!important;">REJECTED</h1>
          <br />
          <p style="font-weight:200;text-align:center;">
            WE'RE SORRY, BUT YOU ARE RECIEVING THIS ERROR MESSAGE DUE TO YOUR ART SUBMISSION NOT
            MEETING OUR CONTENT GUIDELINES
          </p>
          <br />
        </div>
        <div class="row" style="margin: 1rem auto; margin-bottom: 1rem">
          <button
            class="button"
            style="background-color:#111!important;color:#ececec!important;border:unset!important;max-width:300px;margin: auto;"
            v-on:click="(showModerationRejectedModal = false), (showContentGuidelines = true)"
          >
            OK
          </button>
        </div>
      </div>
    </div>
    <div class="modal" v-show="showContentGuidelines">
      <div class="backdrop-close" v-on:click="showContentGuidelines = false"></div>

      <div
        class="error-modal-wrapper"
        style="color:#111!important;background-color:yellow!important;padding:2rem;"
      >
        <div class="">
          <h1 style="text-align:center;font-weight:900!important;">COMMUNITY GUIDELINES</h1>
          <br />
          <h2 style="font-weight:500;">HARMFUL CONTENT:</h2>
          <b
            style="
                          border-radius: 100%;
                          color:yellow!important;text-align:center;
                          background:#111;
                          border: 1px solid #111;
                          font-size: 1rem;
                          font-weight:800;
                          padding: 0rem 0.4rem;
                        "
            >i</b
          >
          <p style="font-weight:200;">
            <b>VIOLENCE AND CRIMINAL BEHAVIOR</b> <br />

            Violence and incitement <br />
            Dangerous Individuals and Organizations <br />
            Coordinating Harm and Promoting Crime <br />
            Restricted Goods and Services <br />
            Fraud and Deception <br />

            <br />
            <b>VIOLATES SAFETY</b> <br />

            Suicide and Self Injury <br />
            Child Sexuasl Exploitation, Abuse, and Nudity <br />
            Adult Sexual Exploitation <br />
            Bullying and Harassment <br />
            Human Exploitation <br />
            Privacy Violations <br />

            <br /><b>OBJECTIONABLE CONTENT</b> <br />

            Hate Speech <br />
            Violent and Graphic Content <br />
            Adult Nudity and Sexual Activity <br />
            Sexual Solicitation <br />

            <br /><b>ACCOUNT INTEGRITY AND AUTHENTICITY</b> <br />
            Account Integrity and Authentic Identity <br />
            Spam <br />
            Cybersecurity <br />
            Inauthentic Behavior <br />
            Memorialization <br />

            <br /><b>INTELLECTUAL PROPERTY</b> <br />
            Violates Intellectual Property Laws <br />
          </p>
          <br />
        </div>
        <div class="row" style="margin: 1rem auto; margin-bottom: 1rem">
          <button
            class="button"
            style="background-color:#111!important;color:#ececec!important;border:unset!important;max-width:300px;margin: auto;"
            v-on:click="
              showContentGuidelines = false;
              showBookingModal = true;
            "
          >
            OK
          </button>
        </div>
      </div>
    </div>

    <div class="modal" v-show="showQuickUpload">
      <div class="backdrop-close" v-on:click="showQuickUpload = false"></div>

      <div class="modal-wrapper oracle-card">
        <div class="modal-container">
          <div class="modal-header">
            <h1 style="margin-bottom: 1rem">Upload An Image</h1>
          </div>

          <div class="modal-body">
            <div class="modal-box">
              <p>Quick Upload From Downloads Folder</p>

              <div class="row-centered" style="">
                <vue-base64-file-upload
                  accept="image/png,image/jpeg"
                  input-class="fileinput hidden"
                  :disable-preview="true"
                  :max-size="customImageMaxSize"
                  aria-placeholder=""
                  placeholder=""
                  ref="fileinput"
                  @size-exceeded="onSizeExceeded"
                  @load="onLoad"
                />
                <button
                  class="button"
                  style="display: flex; flex-flow: row; justify-content: center; margin-top: 1rem"
                  v-on:click="upload(), (showQuickUpload = false)"
                >
                  Select Image
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal" v-show="showClaimedView">
      <div class="backdrop-close" v-on:click="showClaimedView = false"></div>

      <div class="modal-wrapper oracle-card">
        <div class="modal-container">
          <div class="modal-body">
            <div class="modal-box">
              <div class="booking">
                <p style="display: flex; flex-flow: row; justify-content: space-between">
                  CLAIM 001: <b class="aqua">BAYC.jpg</b>
                </p>
                <p style="font-size: 0.5rem !important; opacity: 0.5">
                  Booked Placement Begins <br />{{ range.start }} <br />Booked Placement Ends
                  <br />{{ range.end }}
                </p>
              </div>
              <div class="booking">
                <p style="display: flex; flex-flow: row; justify-content: space-between">
                  CLAIM 002: <b class="aqua">SpacePepes.jpeg</b>
                </p>
                <p style="font-size: 0.5rem !important; opacity: 0.5">
                  Booked Placement Begins <br />{{ range.start }} <br />Booked Placement Ends
                  <br />{{ range.end }}
                </p>
              </div>
              <div class="booking">
                <p style="display: flex; flex-flow: row; justify-content: space-between">
                  CLAIM 003: <b class="aqua">AB.png</b>
                </p>
                <p style="font-size: 0.5rem !important; opacity: 0.5">
                  Booked Placement Begins <br />{{ range.start }} <br />Booked Placement Ends
                  <br />{{ range.end }}
                </p>
              </div>
            </div>
          </div>
          <div
            class="row"
            style="
                    display: flex;
                    flex-flow: row;
                    justify-content: center;margin:1rem 0rem;"
          >
            <button
              class="button"
              style="
                    display: flex;
                    flex-flow: row;
                    max-width:300px;
                    justify-content: center;
                    margin-top: 1rem;
                  "
              v-on:click="(showBookingModal = true), (showClaimedView = false)"
            >
              Back To Booking
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal" v-show="showTutorialBackdrop">
      <div class="backdrop-close" v-on:click="showTutorialBackdrop = showTutorialBackdrop"></div>
    </div>

    <div class="modal" v-show="showClaimConfirm">
      <div class="backdrop-close" v-on:click="showClaimConfirm = false"></div>
    </div>

    <div class="modal" v-show="showBookingModal">
      <div class="backdrop-close" v-on:click="showBookingModal = false"></div>
      <div class="modal-wrapper oracle-card">
        <div class="modal-container">
          <div class="modal-header">
            <h1 style="font-weight: 500;line-height:1;margin-bottom:2rem;">
              IMAGE QUOTE
            </h1>
          </div>

          <div class="modal-body">
            <div class="row">
              <div class="" style="width: 100%">
                <p style="font-size: 0.75rem !important; padding-bottom: 1rem">
                  Your image will remain in orbit around the Earth from <br />{{ range.start }} To
                  <br />{{ range.end }}
                </p>
                <p
                  style="max-width:300px;margin:auto;display: flex; flex-flow: row; justify-content: space-between"
                >
                  GAMMA: <b>{{ getFormattedGammaPrice() }}/{{ getFormattedGammaBalance() }}</b>
                </p>
                <p
                  style="max-width:300px;margin:auto;display: flex; flex-flow: row; justify-content: space-between"
                >
                  KAPPA: <b>{{ getFormattedKappaPrice() }}/{{ getFormattedKappaBalance() }}</b>
                </p>

                <p
                  style="max-width:300px;margin:auto;display: flex; flex-flow: row; justify-content: space-between"
                >
                  BETA:
                  <b> {{ getFormattedBetaPrice() }}/{{ getFormattedBetaBalance() }}</b>
                </p>
                <p
                  style="max-width:300px;margin:auto;display: flex; flex-flow: row; justify-content: space-between"
                >
                  RHO: <b>{{ getFormattedRhoPrice() }}/{{ getFormattedRhoBalance() }}</b>
                </p>

                <p
                  style="max-width:300px;margin:auto;display: flex; flex-flow: row; justify-content: space-between"
                >
                  XI: <b>{{ getFormattedXiPrice() }}/{{ getFormattedXiBalance() }}</b>
                </p>
                <br />

                <div class="row" style="margin: 1rem auto; margin-bottom: unset">
                  <img
                    style="margin:auto;max-width: 300px;margin-bottom: 1rem;"
                    id="predisplayimg"
                  />
                  <!--button class="button" style="max-width:300px;margin: auto;" v-on:click="(showClaimedView = true), (showBookingModal = false)">
                                    View Images
                                </button-->
                </div>

                <div class="row" style="margin: 1rem auto; margin-bottom: 1rem">
                  <button
                    v-if="!$store.state.settings.isAllowanceOK && approvalLoading == false"
                    class="button"
                    style="background-color:yellow;max-width:300px;margin: auto;"
                    v-on:click="dispatchAllowances()"
                  >
                    Approve All
                  </button>
                  <button
                    v-else-if="!$store.state.settings.isAllowanceOK || submissionLoading == true"
                    class="button"
                    style="background-color:yellow;max-width:300px;margin: auto;"
                  >
                    <img
                      style=" filter: invert(1);width:32px;margin:auto;animation:spinloader 1s ease infinite;margin-bottom:-6px;"
                      src="~/@/assets/circleopen.svg"
                    />
                  </button>
                  <button
                    v-else-if="
                      balanceBETA &&
                        balanceBETA.gte(bigBetaPrice) &&
                        balanceGAMMA &&
                        balanceGAMMA.gte(bigGammaPrice) &&
                        balanceKAPPA &&
                        balanceKAPPA.gte(bigKappaPrice) &&
                        balanceRHO &&
                        balanceRHO.gte(bigRhoPrice) &&
                        balanceXI &&
                        balanceXI.gte(bigXiPrice)
                    "
                    class="button"
                    style="background-color:yellow;max-width:300px;margin: auto;"
                    v-on:click="dispatchConfirm()"
                  >
                    Confirm
                  </button>
                  <button
                    v-else
                    class="button"
                    style="background-color:yellow;max-width:300px;margin: auto;"
                  >
                    INSUFFICIENT BALANCE
                  </button>
                </div>
              </div>
            </div>
            <div class="modal-box"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.calendar-box {
    height: 64px;
    display: flex;
    flex-flow: column;
    justify-content: center;
}

.calendar {
    margin: auto;
    display: flex;
    height: 30px;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000 !important;
    backdrop-filter: blur(5px);
    display: table;
    transition: opacity 0.3s ease;
}

.modal-wrapper {
    display: table-cell;
    vertical-align: middle;
    margin: 1rem;
}

.modal-container {
    width: auto;
    transition: all 0.3s ease;
    max-height: calc(100vh - 4rem);
    overflow: auto;
}

.vc-title {
    color: yellow !important;
}

.vc-container {
    .vc-is-dark {
        background-color: transparent !important;
    }
}

.modal-body {}

.modal-footer {}

.modal-header {
    margin: 1rem auto;
    text-align: center;
    text-transform: uppercase;
}

.modal-enter,
.modal-leave {
    opacity: 0;
}

.modal-enter .modal-container,
.modal-leave .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    background: unset !important;
}

.vue-histogram-slider-wrapper {
    margin: 0px !important;
}

.vue-histogram-view {
    overflow: visible !important;
}

.vc-container {
    background-color: transparent !important;
}

.message {
    background-color: #2a2a2ef2 !important;
    font-size: 1rem;
    display: flex;
    height: 64px;
    justify-content: center;
    border-radius: unset !important;
    transition: all 0.2s ease;
}

.col-50 {
    width: 50%;
}

.pixelartEditor {
    width: calc(100% - 64px);
    position: absolute;
    top: 0;
}

input[type='checkbox'] {
    visibility: hidden;
}

.checkboxvalidate {
    width: 40px;
    max-width: 40px;
    min-width: 0px;
    height: 15px;
    background: #2a2a2e;
    margin: 20px 10px;
    position: relative;
    border-radius: 1rem;
    box-shadow: inset 2px 2px 2px #0000005e, inset -2px -2px 2px #ffffff0f, -2px -2px 2px #0000005e, 2px 2px 2px #ffffff0f;
}

.checkboxvalidate label {
    display: block;
    width: 15px;
    height: 15px;
    border-radius: 100%;
    transition: all 0.5s ease;
    cursor: pointer;
    position: absolute;
    top: 0px;
    left: 0px;
    background: #ccc;
    box-shadow: inset -2px -2px 2px #0000005e, inset 2px 2px 2px #ffffff0f;
}

.checkboxvalidate input[type='checkbox'] {
    background-color: yellow;
}

.checkboxvalidate input[type='checkbox']:checked+label {
    background-color: aqua;
    left: 27px;
}

.timebutton {
    min-height: 50px;
    width: calc(100% - 14px);
    border: unset;
    z-index: 1000;
    color: #ececec;
    background-color: #2a2a2ef2;
    margin-top: 7px;
    max-height: 50px;
    margin-top: 7px;
    border-radius: 4px;
    transition: all 0.2s ease;
    font-family: 'Exo 2', sans-serif;
}

.canvas-slider {}

.vsm--link_layer-1:hover>.vsm--icon {
    background: aqua !important;
}

.vsm--mobile-bg {
    background-color: aqua !important;
}

.vsm--title {
    color: #2a2a2ef2 !important;
}

.vsm--item {
    display: flex;
    flex-flow: row;
    justify-content: center;
}

.vsm--link_hover {
    filter: invert(1);
}

.vsm--scroll-wrapper {
    margin-right: unset !important;
}

.selectedtime {
    transition: all 0.2s ease;
    color: aqua;
    font-weight: bold;
    z-index: 2;
}

.shepherd-button {
    background: #363636 !important;
}

.itemscroller {
    border: 1px dashed grey;
    color: black;
    height: 100px;
}

.v-sidebar-menu {
    width: 100% !important;
    max-width: unset !important;
    position: relative !important;
    padding: unset !important;
    background: transparent;
}

.vsm--list {
    margin: auto;
    width: 100% !important;
    display: flex;
    flex-flow: row;
}

.spacebg {
    position: absolute;
    width: 100%;
    height: 100%;
    pointer-events: none;
}

canvas {
    image-rendering: pixelated;
    image-rendering: -moz-crisp-edges;
    image-rendering: -webkit-crisp-edges;
    image-rendering: crisp-edges;
}

.konvajs-content {
    margin: auto;
    background-color: #28282aaa;
    background-size: 60px;
    background-position: 30px 30px;
    background-blend-mode: lighten;
    overflow: hidden;
}

.konvajs-content canvas {
    margin-left: -1px !important;
    margin-top: -1px !important;
}

p {
    margin: 4px;
}

#drag-items img {
    height: 100px;
}

.scroller {
    height: 64px;
}

.menuspace {}

.user {
    height: 32%;
    padding: 0 12px;
    display: flex;
    align-items: center;
}

.backdrop-close {
    z-index: -1;
    position: fixed;
    left: 0px;
    top: 0px;
    height: 100vh;
    width: 100vw;
    background: #111a;
}

.icon {
    width: 10px;
    background-color: unset !important;
}

.download,
.bg,
.brush,
.clear,
.eraser,
.nav,
.rainbow,
.save {
    background-image: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/390561/paintBrush.svg);
    background-position: 50%;
    background-repeat: no-repeat;
}

.spaceswap-price {
    display: flex;margin-bottom:unset;
    font-weight: 400;padding:0.5rem 0rem;
    justify-content: space-between;
}

.spaceswap-price>b {}

.eraser {
    background-image: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/390561/eraser.svg);
}

.bg {
    background-image: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/390561/paintBucket.svg);
}

.clear {
    background-image: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/390561/clear.svg);
}

.save {
    background-image: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/390561/save.svg);
}

.rainbow {
    background-size: contain;
    filter: invert(0.9);
    background-image: url(../assets/editing.svg);
}

.nav {
    background-image: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/390561/nav.svg);
}

.check {
    background-size: contain;
    filter: invert(0.9);
    background-image: url(../assets/review.svg);
}

.home {
    background-size: contain;
    filter: invert(0.9);
    background-image: url(../assets/home.svg);
}

.upload {
    background-size: contain;
    filter: invert(0.9);
    background-image: url(../assets/photo.svg);
}

.download {
    background-size: contain;
    filter: invert(0.9);
    background-image: url(../assets/folder.svg);
}

.satellite {
    background-size: contain;
    filter: invert(0.9);
    background-image: url(../assets/wifi-signal.svg);
}

.oracle {
    background-size: contain;
    filter: invert(0.9);
    background-image: url(../assets/crystal-ball.svg);
}

.wallet {
    background-size: contain;
    filter: invert(0.9);
    background-image: url(../assets/wallet.svg);
}

.vr {
    background-size: contain;
    filter: invert(0.9);
    background-image: url(../assets/vr.svg);
}

.spray {
    background-size: contain;
    filter: invert(0.9);
    background-image: url(../assets/photoplus.svg);
}

.clock {
    right: 2rem;
}

@media screen and (max-width:600px) {
    .spaceswap-price {
        font-weight: 200;
        display: flex;
        font-size: 1rem !important;
        line-height: 1rem !important;
        justify-content: space-between;
    }
    .spaceswap-price>b {
        color: yellow;
        font-weight: 500;
    }
}
</style>

<script>
import Konva from 'konva';
import bufferFrom from 'buffer-from';
import { mapActions, mapState } from 'vuex';
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';
import FileInput from '../components/FileInput.vue';
import VueBase64FileUpload from 'vue-base64-file-upload';
import { Transformer } from 'konva/lib/shapes/Transformer';
import { calculatePrice } from '../calculatePrice';
import data from '../data.json';
import 'vue-histogram-slider/dist/histogram-slider.css';
import { ethers } from 'ethers';
import SatcomModal from '../components/Modal/SatcomOrder.vue';
import SatcomRejectedModal from '../components/Modal/SatcomRejected.vue';
import SatcomPaymentModal from '../components/Modal/SatcomPayment.vue';
import StakingModal from '../components/Modal/Staking.vue';
import StakingConfirmModal from '../components/Modal/StakingConfirm.vue';
import SatcomPaymentConfirmedModal from '../components/Modal/SatcomPaymentConfirmed.vue';
import SatcomOrdersModal from '../components/Modal/SatcomOrders.vue';
import SpaceswapConfirm from '../components/Modal/SpaceswapConfirm.vue';
import Hex from '../components/Hex.vue';

const ipfsgateway = 'https://xi.mypinata.cloud/ipfs/';

const width = 1200; //window.innerWidth;
const height = 1080; //window.innerHeight;

function getTotalBox(boxes) {
    let minX = Infinity;
    let minY = Infinity;
    let maxX = -Infinity;
    let maxY = -Infinity;

    boxes.forEach(box => {
        minX = Math.min(minX, box.x);
        minY = Math.min(minY, box.y);
        maxX = Math.max(maxX, box.x + box.width);
        maxY = Math.max(maxY, box.y + box.height);
    });
    return {
        x: minX,
        y: minY,
        width: maxX - minX,
        height: maxY - minY
    };
}

export default {
    components: {
        /* eslint-disable */
        VueBase64FileUpload,
        FileInput,
        SatcomModal,
        SatcomRejectedModal,
        SatcomPaymentModal,
        StakingModal,
        StakingConfirmModal,
        SatcomPaymentConfirmedModal,
        SatcomOrdersModal,
        SpaceswapConfirm,
        Hex
    },
    data() {
        return {
            data: data.map(d => new Date(d)),
            prettify: function(ts) {
                return new Date(ts).toLocaleDateString('en', {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric'
                });
            },
            range: {
                start: new Date() < new Date(2022, 9, 2) ? new Date(2022, 9, 2) : new Date(),
                end: new Date(2022, 9, 5)
            },
            finalPrice: {
                xi: 0,
                kappa: 0,
                gamma: 0,
                rho: 0,
                beta: 0
            },
            finalImage: null,
            drag: false,
            finalW: null,
            finalH: null,
            finalX: null,
            finalY: null,
            canvasScale: 1,
            rhodata: false,
            betadata: false,
            kappadata: false,
            gammadata: false,
            xidata: false,
            rhodata2: true,
            betadata2: true,
            kappadata2: true,
            gammadata2: true,
            xidata2: true,
            showModerationPendingModal: false,
            showModerationApprovedModal: false,
            showModerationRejectedModal: false,
            showContentGuidelines: false,
            showSwap: false,
            spaceSwapReady: true,
            spaceSwapping: false,
            spaceSwapped: false,
            showReceiptModal: false,
            showStakingModal: false,
            showStakingConfirmModal: false,
            showSatcomModal: false,
            showSatcomOrderModal: false,
            showSatcomRejectedModal: false,
            showSatcomPaymentModal: false,
            showSatcomPaymentConfirmedModal: false,
            showSatcomOrdersModal: false,
            showSatcomFailureModal: false,
            showSpaceswapConfirm: false,
            showTutorialBackdrop: false,
            showBookingModal: false,
            showQuickUpload: false,
            showClaimConfirm: false,
            showClaimedView: false,
            showClaimConfirm: false,
            approvalLoading: false,
            submissionLoading: false,
            pixelartEditor: false,
            checkboxShowValidated: false,
            selectedPeriod: 3,
            slippage: 2,
            customImageMaxSize: 100, // megabytes
            lastPointerPosition: undefined,
            isPaint: true,
            tour: null,
            mode: 'brush',
            itemURL: '',
            hasCollision: false,
            dragItemId: null,
            shadowRectangle: null,
            blockSnapSize: 60,
            stage: null,
            previewStage: null,
            lastValidPosition: null,
            lastValidSize: null,
            windowHeight: window.innerHeight,
            pricesLoading: false,
            finalCost: null,
            base64output: null,

            layer: null,
            myPlacement: null,
            myTransformer: null,

            configKonva: {
                autoDrawEnabled: true,
                width: width,
                height: height
            },
            token: 'USDC'
        };
    },
    masks: {
        input: 'YYYY-MM-DD h:mm A'
    },
    computed: {
        ...mapState({
            submissionReceived: state => state.settings.submissionReceived,
            balanceBETA: state => state.settings.balanceBETA,
            balanceRHO: state => state.settings.balanceRHO,
            balanceKAPPA: state => state.settings.balanceKAPPA,
            balanceGAMMA: state => state.settings.balanceGAMMA,
            balanceXI: state => state.settings.balanceXI,
            tokenPrices: state => state.settings.tokenPrices,
            tokenDecimals: state => state.settings.tokenDecimals
        }),
        bigBetaPrice() {
            if (!this.finalCost || !this.finalCost.price) return 0;
            return ethers.utils.parseEther(this.finalCost.price.beta.toFixed(18).toString());
        },
        bigRhoPrice() {
            if (!this.finalCost || !this.finalCost.price) return 0;
            return ethers.utils.parseEther(this.finalCost.price.rho.toFixed(18).toString());
        },
        bigKappaPrice() {
            if (!this.finalCost || !this.finalCost.price) return 0;
            return ethers.utils.parseEther(this.finalCost.price.kappa.toFixed(18).toString());
        },
        bigGammaPrice() {
            if (!this.finalCost || !this.finalCost.price) return 0;
            return ethers.utils.parseEther(this.finalCost.price.gamma.toFixed(18).toString());
        },
        bigXiPrice() {
            if (!this.finalCost || !this.finalCost.price) return 0;
            return ethers.utils.parseEther(this.finalCost.price.xi.toFixed(18).toString());
        },
        getFormattedTotal() {
            const total = ethers.utils.formatUnits(this.getTotal().toString(), this.tokenDecimals);
            console.log('Total:', total.toString());
            return parseFloat(total).toFixed(6);
        }
    },
    watch: {
        showBookingModal(newBool, oldBool) {
            // only execute on open
            if (newBool === false) {
                return;
            }

            setTimeout(() => {
                const b = localStorage.getItem('b64output');
                document.querySelector('#predisplayimg').src = b;
            }, 1000);
        },
        showModerationPendingModal(newBool, oldBool) {
            // only execute on open
            if (newBool === false) {
                return;
            }

            this.myPlacement.destroy();
            this.myTransformer.destroy();

            setTimeout(async () => {
                await this.pullBookings();
                await this.setBlockedAds();
            }, 500);
        },
        windowHeight(newHeight, oldHeight) {
            this.txt = `it changed to ${newHeight} from ${oldHeight}`;
        },
        async checkboxShowValidated() {
            await this.setBlockedAds();
        },
        async range() {
            // console.log("change range")
            if (this.myPlacement) {
                await this.calcPixelCost();
            }
            await this.setBlockedAds();
        },
        submissionReceived() {
            this.showModerationPendingModal = true;
            this.showBookingModal = false;
            this.submissionLoading = false;
        }
    },
    methods: {
        ...mapActions([
            'init',
            'login',
            'setupAllowances',
            'createBooking',
            'pullBookings',
            'pullStaking',
            'uploadIPFS',
            'getOrders',
            'getTokenPrices',
            'initTokenSwapSubscriptions',
            'setTokenAddress',
            'getTokenAllowance',
            'setTokenName',
            'getTokenBalance',
        ]),
        async dispatchLogin(arg) {
            await this.login();
            await this.pullStaking();
            await this.calcPixelCost();
        },
        async afterInit() {
            await this.pullBookings();
            await this.setBlockedAds();

            // set scrollbar center
            const elem = document.querySelector('#containerstage');
            window.stage = elem;
            const cutoutW = elem.clientWidth;
            const cutoutH = elem.clientHeight;
            const scrollTop = (1080 - cutoutH) / 2;
            const scrollLeft = (1200 - cutoutW) / 2;
            elem.scrollTop = scrollTop;
            elem.scrollLeft = scrollLeft;
        },
        getPrettyWallet() {
            return (
                this.$store.state.settings.address.slice(0, 4) +
                '...' +
                this.$store.state.settings.address.slice(
                    this.$store.state.settings.address.length - 4,
                    this.$store.state.settings.address.length
                )
            );
        },
        closeSpaceswapConfirm() {
            this.showSpaceswapConfirm = false;
            console.log('Closing spaceswap');
        },
        async startSpaceSwap() {
            //sorry future me
            const swappable_tokens = {
                USDC: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
                ETH: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2'
            };
            this.pricesLoading = true;
            this.showSwap = true;
            await this.setTokenAddress(swappable_tokens[this.token]);

            await this.getTokenAllowance();
            await this.getTokenBalance()
            this.setTokenName(this.token);
            await this.getTokenPrices(this.finalPrice);
            this.$refs.spaceswap.setSwap(this.slippage, this.finalCost.price);
            const total = this.getTotal();
            this.pricesLoading = false;
            await this.initTokenSwapSubscriptions(this.finalPrice);
        },
        async dispatchOrders() {
            await this.getOrders();
            this.showSatcomOrdersModal = true;
        },
        async dispatchAllowances() {
            this.approvalLoading = true;
            this.setupAllowances();
        },
        async dispatchConfirm() {
            this.submissionLoading = true;
            const start = Math.floor(this.finalCost.dateRange.start.getTime() / 1000);
            const duration = Math.floor(
                (this.finalCost.dateRange.end.getTime() - this.finalCost.dateRange.start.getTime()) / 1000
            );
            const kappaPrice = ethers.utils.parseEther(this.finalCost.price.kappa.toString());
            const gammaPrice = ethers.utils.parseEther(this.finalCost.price.gamma.toString());
            await this.uploadToIPFS();
            await this.createBooking({
                x: this.finalCost.x,
                y: this.finalCost.y,
                w: this.finalCost.w,
                h: this.finalCost.h,
                t: start,
                d: duration,
                sha: '', //filled by the createBooking itself
                kappa: kappaPrice,
                gamma: gammaPrice
            });
        },
        async uploadToIPFS() {
            const result = await this.analyze(true); // only return bas64 data
            const data = bufferFrom(result.data); // base64 data
            //this.downloadb64(result.data, "bookingData")
            await this.uploadIPFS(data);
        },
        async calcPixelCost() {
            const cost = await this.calculateImageCost();
            this.finalCost = cost;
        },
        async calculateImageCost() {
            const data = await this.analyze();
            if (!data) return {};
            this.finalPrice = data.finalPrice;
            return {
                dateRange: this.range,
                price: data.finalPrice,
                h: data.finalH,
                w: data.finalW,
                x: data.finalX,
                y: data.finalY
            };
        },
        getFormattedTokenPrice(price, decimals) {
            return parseFloat(ethers.utils.formatUnits(price, 6)).toFixed(6);
        },

        getFormattedBetaBalance() {
            return parseFloat(ethers.utils.formatUnits(this.$store.state.settings.balanceBETA)).toFixed(
                4
            );
        },
        getFormattedRhoBalance() {
            return parseFloat(ethers.utils.formatUnits(this.$store.state.settings.balanceRHO)).toFixed(4);
        },
        getFormattedKappaBalance() {
            return parseFloat(ethers.utils.formatUnits(this.$store.state.settings.balanceKAPPA)).toFixed(
                4
            );
        },
        getFormattedGammaBalance() {
            return parseFloat(ethers.utils.formatUnits(this.$store.state.settings.balanceGAMMA)).toFixed(
                4
            );
        },
        getFormattedXiBalance() {
            return parseFloat(ethers.utils.formatUnits(this.$store.state.settings.balanceXI)).toFixed(4);
        },
        getFormattedBetaPrice() {
            if (!this.finalCost || !this.finalCost.price) return 0;
            return this.finalCost.price.beta.toFixed(4);
        },
        getFormattedRhoPrice() {
            if (!this.finalCost || !this.finalCost.price) return 0;
            return this.finalCost.price.rho.toFixed(4);
        },
        getFormattedKappaPrice() {
            if (!this.finalCost || !this.finalCost.price) return 0;
            return this.finalCost.price.kappa.toFixed(4);
        },
        getFormattedGammaPrice() {
            if (!this.finalCost || !this.finalCost.price) return 0;
            return this.finalCost.price.gamma.toFixed(4);
        },
        getFormattedXiPrice() {
            if (!this.finalCost || !this.finalCost.price) return 0;
            return this.finalCost.price.xi.toFixed(0);
        },
        getTotal() {
            const xi = this.$store.state.settings.tokenPrices.xi;
            const beta = this.$store.state.settings.tokenPrices.beta;
            const gamma = this.$store.state.settings.tokenPrices.gamma;
            const kappa = this.$store.state.settings.tokenPrices.kappa;
            const rho = this.$store.state.settings.tokenPrices.rho;
            if (!xi) return ethers.utils.parseEther('0');
            return xi
                .add(beta)
                .add(gamma)
                .add(kappa)
                .add(rho);
        },
        getEtherscanUrl(tx) {
            const url =
                this.$store.state.settings.network == 'rinkeby' ?
                'https://rinkeby.etherscan.io/tx/' :
                'https://etherscan.io/tx/';
            return url + tx.hash;
        },
        getShortHash(hash) {
            if (hash) return hash.substr(0, 6) + '...' + hash.substr(-4);
        },
        chainModals(arg) {
            if (arg.showModerationPendingModal != undefined) {
                this.showModerationPendingModal = arg.showModerationPendingModal;
            }
            if (arg.showModerationApprovedModal != undefined) {
                this.showModerationApprovedModal = arg.showModerationApprovedModal;
            }
            if (arg.showModerationRejectedModal != undefined) {
                this.showModerationRejectedModal = arg.showModerationRejectedModal;
            }
            if (arg.showContentGuidelines != undefined) {
                this.showContentGuidelines = arg.showContentGuidelines;
            }
            if (arg.showReceiptModal != undefined) {
                this.showReceiptModal = arg.showReceiptModal;
            }
            if (arg.showStakingModal != undefined) {
                this.showStakingModal = arg.showStakingModal;
            }
            if (arg.showStakingConfirmModal != undefined) {
                this.showStakingConfirmModal = arg.showStakingConfirmModal;
            }
            if (arg.showSatcomModal != undefined) {
                this.showSatcomModal = arg.showSatcomModal;
            }
            if (arg.showSatcomOrderModal != undefined) {
                this.showSatcomOrderModal = arg.showSatcomOrderModal;
            }
            if (arg.showSatcomRejectedModal != undefined) {
                this.showSatcomRejectedModal = arg.showSatcomRejectedModal;
            }
            if (arg.showSatcomPaymentModal != undefined) {
                this.showSatcomPaymentModal = arg.showSatcomPaymentModal;
            }
            if (arg.showSatcomPaymentConfirmedModal != undefined) {
                this.showSatcomPaymentConfirmedModal = arg.showSatcomPaymentConfirmedModal;
            }
            if (arg.showSatcomFailureModal != undefined) {
                this.showSatcomFailureModal = arg.showSatcomFailureModal;
            }
            if (arg.showTutorialBackdrop != undefined) {
                this.showTutorialBackdrop = arg.showTutorialBackdrop;
            }
            if (arg.showBookingModal != undefined) {
                this.showBookingModal = arg.showBookingModal;
            }
            if (arg.showQuickUpload != undefined) {
                this.showQuickUpload = arg.showQuickUpload;
            }
            if (arg.showClaimConfirm != undefined) {
                this.showClaimConfirm = arg.showClaimConfirm;
            }
            if (arg.showClaimedView != undefined) {
                this.showClaimedView = arg.showClaimedView;
            }
            if (arg.showSatcomOrdersModal != undefined) {
                this.showSatcomOrdersModal = arg.showSatcomOrdersModal;
            }
        },
        onResize() {
            this.windowHeight = window.innerHeight;
        },
        home() {
            location.href = '/#/';
        },
        upload() {
            this.$refs.fileinput.$el.children[1].children[0].click();
        },
        async downloadPlacement() {
            // console.log("download+analyze")
            await this.analyze();
        },
        currentDateTime() {
            const current = new Date();
            const date =
                current.getUTCFullYear() + '-' + (current.getUTCMonth() + 1) + '-' + current.getUTCDate();
            const time =
                current.getUTCHours() + ':' + current.getUTCMinutes() + ':' + current.getUTCSeconds();
            const dateTime = date + ' ' + time;

            return dateTime;
        },
        async analyze(getBase64Data) {
            const duration = Math.floor((this.range.end.getTime() - this.range.start.getTime()) / 1000);
            if (!this.myPlacement) return;
            const x = this.myPlacement.x();
            const y = this.myPlacement.y();
            const w = this.myPlacement.width();
            const h = this.myPlacement.height();

            const stages = document.querySelectorAll('#containerstage canvas');

            const ctx0Stage = stages[0].getContext('2d');
            ctx0Stage.webkitImageSmoothingEnabled = false;
            ctx0Stage.mozImageSmoothingEnabled = false;
            ctx0Stage.imageSmoothingEnabled = false;

            const ctx1Stage = stages[1].getContext('2d');
            ctx1Stage.webkitImageSmoothingEnabled = false;
            ctx1Stage.mozImageSmoothingEnabled = false;
            ctx1Stage.imageSmoothingEnabled = false;

            const b64 = this.savePlacement();
            // this.downloadb64(b64, "ad")

            const p = new Promise((resolve, reject) => {
                const image = new Image();
                image.onload = () => {
                    // console.log(w, h, x, y);

                    const canvas = document.createElement('canvas');
                    canvas.width = image.width;
                    canvas.height = image.height;
                    const context = canvas.getContext('2d');
                    context.webkitImageSmoothingEnabled = false;
                    context.mozImageSmoothingEnabled = false;
                    context.imageSmoothingEnabled = false;

                    context.drawImage(image, 0, 0, w, h, 0, 0, w, h);
                    const imgFinalb64 = canvas.toDataURL();
                    //this.downloadb64(imgFinalb64, 'CroppedPlacement');

                    // get pixels again
                    const imageData = context.getImageData(0, 0, w, h);

                    /* gegen check
                                        const canvas2 = document.createElement('canvas');
                                        canvas2.width = image.width;
                                        canvas2.height = image.height;
                                        const context2 = canvas2.getContext('2d');
                                        context2.webkitImageSmoothingEnabled = false;
                                        context2.mozImageSmoothingEnabled = false;
                                        context2.imageSmoothingEnabled = false;

                                        context2.putImageData(imageData, 0, 0)
                                        this.downloadb64(canvas2.toDataURL(), 'imgData2');
                                        */

                    const pixelRGBArray = [];

                    for (let xx = 0; xx < w; xx++) {
                        for (let yy = 0; yy < h; yy++) {
                            let index = (yy * imageData.width + xx) * 4;
                            let red = imageData.data[index];
                            let green = imageData.data[index + 1];
                            let blue = imageData.data[index + 2];
                            let alpha = imageData.data[index + 3];
                            pixelRGBArray.push({
                                red,
                                green,
                                blue,
                                alpha
                            });
                        }
                    }

                    const out = {
                        //finalImage: imgFinalb64,
                        finalPixelsAmount: pixelRGBArray.length,
                        finalW: w,
                        finalH: h,
                        finalX: x,
                        finalY: y
                    };

                    out.finalPrice = calculatePrice({
                        seconds: duration,
                        x: out.finalX,
                        y: out.finalY,
                        w: out.finalW,
                        h: out.finalH,
                        pixelsRGB: pixelRGBArray
                    });
                    out.seconds = duration;

                    console.log('resolving');

                    if (getBase64Data) {
                        resolve({
                            data: imgFinalb64
                        });
                        return;
                    }

                    resolve(out);
                };
                image.src = b64;
            });

            return p;
        },
        downloadb64(b64, name = 'image') {
            var link = document.createElement('a');
            link.download = name + '.png';
            link.href = b64;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            // mark for GC
            link = null;
        },
        downloadStage() {
            const b64 = this.saveStage({
                pixelRatio: 1
            });
            this.downloadb64(b64, 'stage');
        },
        savePlacement() {
            // console.log("savePlacement")
            var dataURL = this.myPlacement.toDataURL({
                pixelRatio: 1
            });

            window.localStorage.setItem('b64output', dataURL);

            return dataURL;
        },
        saveStage() {
            this.previewStage.setAttrs({
                scaleX: 1,
                scaleY: 1,
                width,
                height
            });

            var dataURL = this.previewStage.toDataURL({
                pixelRatio: 1
            });

            this.previewStage.setAttrs({
                scaleX: 1 / 8,
                scaleY: 1 / 8,
                width: width / 8,
                height: height / 8
            });

            return dataURL;
        },
        loadPixelArt() {
            const data = JSON.parse(localStorage.getItem('xi-pixelart'));
            if (data) {
                this.onLoad(data.url);
            }
        },
        handleDragend(e) {
            this.dragItemId = null;
        },
        handleDragover(e) {
            // console.log(e);
        },
        handleDragstart(e) {
            // console.log(e);
        },
        onLoad(dataUri) {
            Konva.Image.fromURL(dataUri, image => {
                const x = this.blockSnapSize * 1;
                const y = this.blockSnapSize * 1;

                const size = this.blockSnapSize;

                // replace old placement we can only have one
                if (this.myPlacement) {
                    this.myPlacement.destroy();
                    this.myTransformer.destroy();
                }

                image.setAttrs({
                    x: x,
                    y: y,
                    width: size,
                    height: size,
                    draggable: true,
                    name: 'myAd'
                });

                this.shadowRectangle.setAttrs({
                    width: size,
                    height: size,
                    name: 'shadowRect'
                });

                this.myPlacement = image;
                this.lastValidPosition = {
                    x,
                    y
                };
                this.lastValidSize = {
                    w: size,
                    h: size
                };

                const crop = this.getCrop(
                    image.image(), {
                        width: image.width(),
                        height: image.height()
                    },
                    'center-top'
                );

                image.setAttrs(crop);

                const tr = new Konva.Transformer({
                    rotateEnabled: false,
                    nodes: [image],
                    enabledAnchors: ['bottom-right'],
                    keepRatio: false,
                    flipEnabled: false,
                    anchorStroke: 'transparent',
                    anchorFill: 'teal',
                    anchorSize: 15,
                    borderDash: [7.5, 7.5],
                    boundBoxFunc: (oldBox, newBox) => {
                        const box = newBox;

                        const isOut =
                            box.x < 0 ||
                            box.y < 0 ||
                            box.x + box.width > this.stage.width() ||
                            box.y + box.height > this.stage.height();

                        // if new bounding box is out of visible viewport, let's just skip transforming
                        // this logic can be improved by still allow some transforming if we have small available space

                        if (isOut) {
                            return oldBox;
                        }

                        // if bounding box is less than x / y position (negative scale) return
                        return newBox;
                    }
                });

                tr.anchorDragBoundFunc((oldAbsPos, newAbsPos, event) => {
                    // bail if trying to sclae negatively outside x and y of image w and h
                    if (
                        newAbsPos.x <= oldAbsPos.x - image.attrs.width ||
                        newAbsPos.y <= oldAbsPos.y - image.attrs.height
                    ) {
                        return oldAbsPos;
                    }

                    return {
                        x: Math.round(newAbsPos.x / this.blockSnapSize) * this.blockSnapSize,
                        y: Math.round(newAbsPos.y / this.blockSnapSize) * this.blockSnapSize
                    };
                });

                tr.on('dragmove', e => {
                    const boxes = tr.nodes().map(node => node.getClientRect());
                    const box = getTotalBox(boxes);
                    tr.nodes().forEach(shape => {
                        const absPos = shape.getAbsolutePosition();
                        // where are shapes inside bounding box of all shapes?
                        const offsetX = box.x - absPos.x;
                        const offsetY = box.y - absPos.y;

                        // we total box goes outside of viewport, we need to move absolute position of shape
                        const newAbsPos = {
                            ...absPos
                        };
                        if (box.x < 0) {
                            newAbsPos.x = -offsetX;
                        }
                        if (box.y < 0) {
                            newAbsPos.y = -offsetY;
                        }
                        if (box.x + box.width > this.stage.width()) {
                            newAbsPos.x = this.stage.width() - box.width - offsetX;
                        }
                        if (box.y + box.height > this.stage.height()) {
                            newAbsPos.y = this.stage.height() - box.height - offsetY;
                        }

                        shape.setAbsolutePosition(newAbsPos);
                    });
                });

                this.layer.add(tr);

                this.myTransformer = tr;

                this.layer.clear();
                this.layer.draw();

                this.clearCollision();

                image.on('transformend', e => {
                    // reset scale on transform
                    const w = image.width() * image.scaleX();
                    const h = image.height() * image.scaleY();
                    const imgW = image.width();
                    const imgH = image.height();

                    if (w < this.blockSnapSize / 2) {
                        tr.stopTransform();
                        // reset visible so future transform is possible
                        let scaleX = this.blockSnapSize * imgW;
                        // console.log(scaleX);
                        if (scaleX > 1) {
                            scaleX = 1;
                        }
                        //const width = this.blockSnapSize
                        image.setAttrs({
                            scaleX
                            //width
                        });
                    }

                    if (h < this.blockSnapSize / 2) {
                        tr.stopTransform();
                        // reset visible so future transform is possible
                        let scaleY = this.blockSnapSize * imgH;
                        // console.log(scaleY);
                        if (scaleY > 1) {
                            scaleY = 1;
                        }
                        //const height = this.blockSnapSize
                        image.setAttrs({
                            scaleY
                            //height
                        });
                    }

                    if (h < this.blockSnapSize / 2 || w < this.blockSnapSize / 2) {
                        image.position({
                            x: Math.round(image.x() / this.blockSnapSize) * this.blockSnapSize,
                            y: Math.round(image.y() / this.blockSnapSize) * this.blockSnapSize
                        });
                        return null;
                    }

                    let snapW = Math.round(w / this.blockSnapSize) * this.blockSnapSize;
                    let snapH = Math.round(h / this.blockSnapSize) * this.blockSnapSize;

                    const hasCol = this.checkCollision(e);
                    if (hasCol) {
                        tr.stopTransform();
                        //console.log("COLLISION Transform")
                        // reset width
                        snapW = Math.round(this.lastValidSize.w / this.blockSnapSize) * this.blockSnapSize;
                        snapH = Math.round(this.lastValidSize.h / this.blockSnapSize) * this.blockSnapSize;
                        this.clearCollision();
                    }

                    image.setAttrs({
                        scaleX: 1,
                        scaleY: 1,
                        width: snapW,
                        height: snapH
                    });

                    this.shadowRectangle.setAttrs({
                        scaleX: 1,
                        scaleY: 1,
                        width: snapW,
                        height: snapH
                    });

                    const crop = this.getCrop(
                        image.image(), {
                            width: image.width(),
                            height: image.height()
                        },
                        'center-top'
                    );

                    image.setAttrs(crop);

                    // compensate for errors from transforming
                    image.position({
                        x: Math.round(image.x() / this.blockSnapSize) * this.blockSnapSize,
                        y: Math.round(image.y() / this.blockSnapSize) * this.blockSnapSize
                    });

                    this.lastValidSize.w = snapW;
                    this.lastValidSize.h = snapH;

                    setTimeout(async () => {
                        await this.calcPixelCost();
                    }, 1000);
                });

                image.on('transform', e => {
                    // reset scale on transform
                    const w = image.width() * image.scaleX();
                    const h = image.height() * image.scaleY();
                    const imgW = image.width();
                    const imgH = image.height();

                    const hasCol = this.checkCollision(e);

                    if (w < this.blockSnapSize / 2) {
                        tr.stopTransform();
                        // reset visible so future transform is possible
                        let scaleX = this.blockSnapSize * imgW;
                        // console.log(scaleX);
                        if (scaleX > 1) {
                            scaleX = 1;
                        }
                        //const width = this.blockSnapSize
                        image.setAttrs({
                            scaleX
                            //width
                        });
                    }

                    if (h < this.blockSnapSize / 2) {
                        tr.stopTransform();
                        // reset visible so future transform is possible
                        let scaleY = this.blockSnapSize * imgH;
                        // console.log(scaleY);
                        if (scaleY > 1) {
                            scaleY = 1;
                        }
                        //const height = this.blockSnapSize
                        image.setAttrs({
                            scaleY
                            //height
                        });
                    }

                    if (h < this.blockSnapSize / 2 || w < this.blockSnapSize / 2) {
                        image.position({
                            x: Math.round(image.x() / this.blockSnapSize) * this.blockSnapSize,
                            y: Math.round(image.y() / this.blockSnapSize) * this.blockSnapSize
                        });
                        return null;
                    }

                    //console.log('transform');

                    const snapW = Math.round(w / this.blockSnapSize) * this.blockSnapSize;
                    const snapH = Math.round(h / this.blockSnapSize) * this.blockSnapSize;

                    image.setAttrs({
                        scaleX: 1,
                        scaleY: 1,
                        width: snapW,
                        height: snapH
                    });

                    const crop = this.getCrop(
                        image.image(), {
                            width: image.width(),
                            height: image.height()
                        },
                        'center-top'
                    );

                    image.setAttrs(crop);

                    // compensate for errors from transforming
                    image.position({
                        x: Math.round(image.x() / this.blockSnapSize) * this.blockSnapSize,
                        y: Math.round(image.y() / this.blockSnapSize) * this.blockSnapSize
                    });
                });

                image.on('dragstart', e => {
                    // console.log('dragstart');
                    this.shadowRectangle.show();
                    this.shadowRectangle.moveToTop();
                    image.moveToTop();
                });

                image.on('dragend', e => {
                    // console.log('dragend');
                    const imgWidth = image.width();
                    const imgHeight = image.height();

                    const griddedPos = {
                        x: Math.round(image.x() / this.blockSnapSize) * this.blockSnapSize,
                        y: Math.round(image.y() / this.blockSnapSize) * this.blockSnapSize
                    };

                    //console.log(image.x(), image.y())
                    //console.log(griddedPos)

                    // dont let set if its above another one, out of bounds, or otherwise invalid

                    // is out of bounds ?
                    //console.log(griddedPos)
                    //console.log(imgWidth, imgHeight)
                    //console.log(image.x(), image.y())

                    const hasCol = this.checkCollision(e);

                    if (hasCol) {
                        image.position({
                            x: this.lastValidPosition.x,
                            y: this.lastValidPosition.y
                        });
                        this.stage.batchDraw();
                        this.shadowRectangle.hide();
                        return;
                    }

                    image.position({
                        x: griddedPos.x,
                        y: griddedPos.y
                    });

                    this.lastValidPosition = {
                        x: griddedPos.x,
                        y: griddedPos.y
                    };

                    this.stage.batchDraw();
                    this.shadowRectangle.hide();
                });

                image.on('dragmove', () => {
                    // console.log('dragmove');

                    this.shadowRectangle.position({
                        x: Math.round(image.x() / this.blockSnapSize) * this.blockSnapSize,
                        y: Math.round(image.y() / this.blockSnapSize) * this.blockSnapSize
                    });

                    const shadowRectColl = this.checkShadowCollision();

                    // is shadow rect out of stage?
                    const rectX = this.shadowRectangle.position().x;
                    const rectY = this.shadowRectangle.position().y;
                    const rectW = this.shadowRectangle.width();
                    const rectH = this.shadowRectangle.height();
                    const stageW = this.stage.width();
                    const stageH = this.stage.height();

                    const isOutOfBounds = rectX + rectW > stageW || rectY + rectH > stageH;
                    // console.log(isOutOfBounds)

                    if (!isOutOfBounds && !shadowRectColl) {
                        this.lastValidPosition = {
                            x: Math.round(image.x() / this.blockSnapSize) * this.blockSnapSize,
                            y: Math.round(image.y() / this.blockSnapSize) * this.blockSnapSize
                        };
                    }

                    this.stage.batchDraw();
                });

                this.layer.add(image);

                setTimeout(async () => {
                    await this.calcPixelCost();
                    await this.checkCollision({
                            target: undefined
                        },
                        image
                    );

                    const elem = document.querySelector('#containerstage');
                    elem.scrollTop = 0;
                    elem.scrollLeft = 0;
                }, 500);
            });

            // this.tour.next()
        },
        onSizeExceeded(size) {
            alert(`Image ${size}Mb size exceeds limits of ${this.customImageMaxSize}Mb!`);
        },
        getCrop(image, size, clipPosition = 'center-middle') {
            // function to calculate crop values from source image, its visible size and a crop strategy
            const width = size.width;
            const height = size.height;
            const aspectRatio = width / height;

            let newWidth;
            let newHeight;

            const imageRatio = image.width / image.height;

            if (aspectRatio >= imageRatio) {
                newWidth = image.width;
                newHeight = image.width / aspectRatio;
            } else {
                newWidth = image.height * aspectRatio;
                newHeight = image.height;
            }

            let x = 0;
            let y = 0;
            if (clipPosition === 'left-top') {
                x = 0;
                y = 0;
            } else if (clipPosition === 'left-middle') {
                x = 0;
                y = (image.height - newHeight) / 2;
            } else if (clipPosition === 'left-bottom') {
                x = 0;
                y = image.height - newHeight;
            } else if (clipPosition === 'center-top') {
                x = (image.width - newWidth) / 2;
                y = 0;
            } else if (clipPosition === 'center-middle') {
                x = (image.width - newWidth) / 2;
                y = (image.height - newHeight) / 2;
            } else if (clipPosition === 'center-bottom') {
                x = (image.width - newWidth) / 2;
                y = image.height - newHeight;
            } else if (clipPosition === 'right-top') {
                x = image.width - newWidth;
                y = 0;
            } else if (clipPosition === 'right-middle') {
                x = image.width - newWidth;
                y = (image.height - newHeight) / 2;
            } else if (clipPosition === 'right-bottom') {
                x = image.width - newWidth;
                y = image.height - newHeight;
            } else if (clipPosition === 'scale') {
                x = 0;
                y = 0;
                newWidth = width;
                newHeight = height;
            } else {
                console.error(new Error('Unknown clip position property - ' + clipPosition));
            }

            return {
                cropX: x,
                cropY: y,
                cropWidth: newWidth,
                cropHeight: newHeight
            };
        },
        clearCollision() {
            this.layer.children.forEach(function(child) {
                if (child.name() === 'ad') {
                    child.fill('transparent');
                }
            });
            this.hasCollision = false;
            return;
        },
        checkShadowCollision() {
            let hasCollision = false;

            var target = this.shadowRectangle;
            var boundingBox = target.getClientRect();

            if (target instanceof Transformer) {
                return;
            }

            this.layer.children.forEach(function(child) {
                // do not check intersection with itself, non-ads or transfomers
                if (child === target || child.name() !== 'ad') {
                    return;
                }

                const col = {
                    x: child.x() + 0.5,
                    y: child.y() + 0.5,
                    width: child.width() - 1,
                    height: child.height() - 1
                };

                function haveIntersection(r1, r2) {
                    return !(
                        r2.x > r1.x + r1.width ||
                        r2.x + r2.width < r1.x ||
                        r2.y > r1.y + r1.height ||
                        r2.y + r2.height < r1.y
                    );
                }

                if (haveIntersection(col, boundingBox)) {
                    child.fill('indianred');
                    hasCollision = true;
                } else {
                    child.fill('transparent');
                }
            });

            this.hasCollision = hasCollision;

            return hasCollision;
        },
        checkCollision(e, tget) {
            let hasCollision = false;

            var target = e.target;
            if (tget) {
                target = tget;
            }
            var boundingBox = target.getClientRect();

            if (target instanceof Transformer) {
                return;
            }

            this.layer.children.forEach(function(child) {
                // do not check intersection with itself, non-ads or transfomers
                if (child === target || child.name() !== 'ad') {
                    return;
                }

                const col = {
                    x: child.x() + 0.5,
                    y: child.y() + 0.5,
                    width: child.width() - 1,
                    height: child.height() - 1
                };

                function haveIntersection(r1, r2) {
                    return !(
                        r2.x > r1.x + r1.width ||
                        r2.x + r2.width < r1.x ||
                        r2.y > r1.y + r1.height ||
                        r2.y + r2.height < r1.y
                    );
                }

                if (haveIntersection(col, boundingBox)) {
                    child.fill('indianred');
                    hasCollision = true;
                } else {
                    child.fill('transparent');
                }
            });

            this.hasCollision = hasCollision;

            return hasCollision;
        },
        async setBlockedAds() {
            const that = this;
            // Blocked Grid

            // clear old ones
            this.stage.find('.ad').forEach(ad => {
                ad.destroy();
            });

            // console.log(this.$store.state.settings.submissions)

            // filtering
            this.$store.state.settings.submissions.forEach(async (submission, id) => {
                // early bail on rejected submission (struct is zeroed)
                if (submission.width <= 0 || submission.height <= 0) {
                    return;
                }

                const subDurationMs = submission.duration.toString() * 1000;
                const subStartMs = submission.time.toString() * 1000;
                const subEndMs = subStartMs + subDurationMs;
                const rangeStartMs = new Date(this.range.start).getTime();
                const rangeEndMs = new Date(this.range.end).getTime();

                // Completely before range
                if (subStartMs < rangeStartMs && subEndMs < rangeStartMs) {
                    return;
                }

                // Completely after range
                if (subStartMs > rangeEndMs && subEndMs > rangeEndMs) {
                    return;
                }

                // filter validated or not
                /*
                                if (this.checkboxShowValidated) {
                                    if (!submission.validated) {
                                        return
                                    }
                                }
                                */

                // naughty list

                const bList = {
                    28: null
                };

                // early bail on banned submission
                if (bList[id] !== undefined) {
                    // console.log("naughty! "+id)
                    // console.log(submission)
                    return;
                }

                // if not validated show a default image?
                if (!submission.validated) {
                    // hook here for using ads of images for unvalidated areas
                    const defaultImages = {
                        //"QmZf2Uba8aWQCooXWh7CwXkmDRroZ61PWsmmYNWunsYyZK"
                    };

                    const lol = new Konva.Rect({
                        x: submission.x,
                        y: submission.y,
                        width: submission.width,
                        height: submission.height,
                        // draggable: false,
                        name: 'ad',
                        strokeWidth: 2, // border width
                        stroke: 'gray' // border color
                    });

                    if (window.NOTADMIN) {
                        lol.on('click', function() {
                            console.log(submission);
                        });
                    }

                    this.layer.add(lol);

                    lol.moveToBottom();
                    if (this.myPlacement) this.myPlacement.moveToTop();

                    return;
                }

                const url = ipfsgateway + submission.sha;
                // console.log(url)
                const call = await fetch(url);
                const result = await call.blob();
                const data = await result.text();
                const json = JSON.parse(data);

                //const b64 = String.fromCharCode(...json.image); // BAD BAD ALGO
                const b64 = new Uint8Array(json.image).reduce(function(data, byte) {
                    return data + String.fromCharCode(byte);
                }, '');

                Konva.Image.fromURL(b64, image => {
                    image.setAttrs({
                        x: submission.x,
                        y: submission.y,
                        width: submission.width,
                        height: submission.height,
                        // draggable: false,
                        name: 'ad'
                    });

                    if (window.NOTADMIN) {
                        image.on('click', function() {
                            console.log(submission);
                        });
                    }

                    this.layer.add(image);
                    image.moveToBottom();
                    if (this.myPlacement) this.myPlacement.moveToTop();
                });

                return;
            });
        }
    },
    async mounted() {
        await this.init();

        window.RADIO.on('test', val => {
            console.log(this.finalPrice);
        });

        /* tour
    this.$nextTick( () => {
      
      this.tour = this.$shepherd({
        classes: "xi_custom_tour",
        useModalOverlay: true,
      });

      // Welcome (always)
      this.tour.addStep({
          attachTo: { element: "menuspace", on: 'top' },
          id: "tourWelcome",
          arrow: false,
          title: "Welcome to Spacevertising",
          text: 'This Editor will help you make a Placement on the Sat in Lunar Orbit. If you want the Tutorial Click "Next". Otherwise Please Connect Your Wallert.',
          buttons: [
              {
                  action() { return this.back() },
                  text: 'Connect Wallet'
              },
              {
                  action() { return this.next() },
                  text: 'Start Tour'
              }
          ]
      });

      setupTour(this.tour)

      this.tour.addStep({
        attachTo: { element: "#preview", on: 'bottom' },
        id: "tourStepLast",
        title: "Preview",
        text: 'This is the Preview Screen that helps you navigate.',
        buttons: [
            {
                action() {  this.showTutorialBackdrop = false; return this.next() },
                text: 'Close'
            }
        ]
      });

      this.tour.start();
    });
*/
        /*
                let restrictedAreaGroup = new Konva.Group({
                  x: 30,
                  y: 70,
                  draggable: true
                });
                */

        const s = this.$refs.stage.getStage();
        this.stage = s;
        // const l = this.$refs.layer.getNode();
        const layer = new Konva.Layer();
        this.layer = layer;
        const con = s.container();

        //
        // PREVIEW SCREEN
        //

        // create smaller preview stage
        this.previewStage = new Konva.Stage({
            container: 'preview',
            width: width / 8,
            height: height / 8,
            scaleX: 1 / 8,
            scaleY: 1 / 8
        });

        setInterval(() => {
            // remove all layer
            previewLayer.destroy();
            // generate new one
            previewLayer = layer.clone({
                listening: false
            });

            const stageC = document.getElementById('containerstage');
            const stageCHeight = stageC.clientHeight;
            const stageCWidth = stageC.clientWidth;
            const scrollPosX = stageC.scrollLeft;
            const scrollPosY = stageC.scrollTop;
            const viewport = new Konva.Rect({
                x: scrollPosX,
                y: scrollPosY,
                width: stageCWidth,
                height: stageCHeight,
                fill: 'teal',
                opacity: 0.5,
                stroke: 'black',
                strokeWidth: 1,
                dash: [1, 1]
            });

            previewLayer.add(viewport);

            this.previewStage.add(previewLayer);
        }, 1000);

        // clone original layer, and disable all events on it
        // we will use "let" here, because we can redefine layer later
        let previewLayer = layer.clone({
            listening: false
        });
        this.previewStage.add(previewLayer);

        const nativeCtx = layer.getContext()._context;
        nativeCtx.webkitImageSmoothingEnabled = false;
        nativeCtx.mozImageSmoothingEnabled = false;
        nativeCtx.imageSmoothingEnabled = false;

        // layer.toDataURL({ imageSmoothingEnabled: false });

        //
        // END PREVIEW SCREEN
        //

        //
        // Placement Grid
        //

        const blockSnapSize = this.blockSnapSize;

        this.shadowRectangle = new Konva.Rect({
            x: 0,
            y: 0,
            width: blockSnapSize,
            height: blockSnapSize,
            fill: '#28282aaa',
            opacity: 0.66,
            //stroke: 'teal',
            //strokeWidth: 1,
            dash: [20, 2]
        });

        const gridLayer = new Konva.Layer();
        const padding = blockSnapSize;
        //console.log(width, padding, width / padding);
        for (let i = 0; i < width / padding; i++) {
            gridLayer.add(
                new Konva.Line({
                    points: [Math.round(i * padding) + 0.5, 0, Math.round(i * padding) + 0.5, height],
                    stroke: i === 10 ? 'aqua' : '#aaaaaa',
                    strokeWidth: 0
                })
            );
        }

        gridLayer.add(
            new Konva.Line({
                points: [0, 0, 10, 10]
            })
        );
        for (let j = 0; j < height / padding; j++) {
            gridLayer.add(
                new Konva.Line({
                    points: [0, Math.round(j * padding), width, Math.round(j * padding)],
                    stroke: j === 9 ? 'aqua' : '#aaaaaa',
                    strokeWidth: 0
                })
            );
        }

        this.shadowRectangle.hide();
        layer.add(this.shadowRectangle);

        s.add(gridLayer);
        s.add(layer);

        layer.on('dragmove', e => this.checkCollision(e));
        layer.on('dragend', e => this.checkCollision(e));

        window.RADIO.on('tabfocus', () => {
            // console.log("focus gained")
            layer.batchDraw();
        });

        await this.afterInit();
    }
};
</script>
