<template>
  <Modal :open="open" @close="$emit('close')">
    <div class="modal">
        <div class="backdrop-close" v-on:click="$emit('close')"></div>      
        <div class="modal-wrapper satcom-card">
            <div class="modal-container">
                <div class="modal-header">
                    <img src="~/@/assets/xi.svg" style="width:128px;margin:auto;" alt="">
                </div>

                <div class="modal-body">
                    <div class="modal-box">
                            <div style='width:100%; display:flex;flex-flow:wrap;margin-bottom:1rem;' v-for='order in $store.state.settings.orbCommOrders.data' :key='order.data.id' v-on:click='showOrder(order.data.id)'>
                                <span style='margin: 0px 15px;'>{{order.data.status}}</span>
                                <span style='margin: 0px 15px;'>{{order.data.address.name}}</span>
                                <span style='margin: 0px 15px;'>{{order.data.address.email}}</span>
                                <span style='margin: 0px 15px;'>{{order.data.address.phone}}</span>
                                <div v-if='clicked == order.data.id'>
                                   {{order.data.ethAddress}}<br />
                                   {{order.data.address.name}}<br />
                                   {{order.data.address.street}}<br />
                                   {{order.data.address.aptNo}}<br v-if='order.data.address.aptNo' />
                                   {{order.data.address.city}}, {{order.data.address.state}}<br />
                                   {{order.data.address.zip}} {{order.data.address.country}}<br />
                                </div>
                                <br />
                            </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
  </Modal>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import countries from '@/helpers/countries.json';

export default {
  props: ['open'],
  data() {
    return {
        clicked: 0
    }
  },
  methods: {
      ...mapActions(['getOrder']),
      showOrder(id) {
          this.clicked = this.clicked == id ? 0 : id;
      },
  }
};
</script>
