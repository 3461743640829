<template>
    <Modal :open="open" @close="$emit('close')" :transparentBackground="true">
        <div class="modal-head">
            <h2 class="">Confirm Swap</h2>
        </div>
        <div class="modal-body">
            <div class="out">
                <div class="token">
                    <div class="amount">{{ slippage }}%</div>
                    <div class="name">
                        Slippage
                    </div>
                </div>
                <div class="token">
                    <div class="amount">{{ getFormattedTotalPrice(6) }}<small>(max)</small></div>
                    <div class="name">
                        {{ tokenInName }}
                    </div>
                </div>
            </div>
            <img class="down" src="@/assets/down2.svg" />
            <div class="out">
                <div class="token">
                    <div class="amount">
                        {{ getFormattedTokenPrice(amountsOut.xi, 6) }}
                    </div>
                    <div class="name">
                        XI
                    </div>
                </div>
                <div class="token">
                    <div class="amount">
                        {{ getFormattedTokenPrice(amountsOut.beta, 6) }}
                    </div>
                    <div class="name">
                        BETA
                    </div>
                </div>
                <div class="token">
                    <div class="amount">
                        {{ getFormattedTokenPrice(amountsOut.kappa, 6) }}
                    </div>
                    <div class="name">
                        KAPPA
                    </div>
                </div>
                <div class="token">
                    <div class="amount">
                        {{ getFormattedTokenPrice(amountsOut.gamma, 6) }}
                    </div>
                    <div class="name">
                        GAMMA
                    </div>
                </div>
                <div class="token">
                    <div class="amount">
                        {{ getFormattedTokenPrice(amountsOut.rho, 6) }}
                    </div>
                    <div class="name">
                        RHO
                    </div>
                </div>
            </div>
            <div v-if="checkBalance">
                <button v-if="!checkAllowance && loading === false" class="confirm" @click="dispatchTokenApproval">
                      Approve
                    </button>
    
                <Hex class="hex" v-else-if="!checkAllowance && loading === true" />
                <button v-else-if="swapLoading == false && checkAllowance === true" class="confirm" @click="dispatchSwap">
                      Confirm
                    </button>
                <Hex class="hex" v-else-if="checkAllowance && swapLoading === true" />
            </div>
            <button v-else class="confirm">
                    Insufficient Balance
                  </button>
        </div>
    </Modal>
</template>

<script>
import store from '@/store';
import { ethers } from 'ethers';
import { mapState, mapActions } from 'vuex';
import Hex from '../Hex.vue';

export default {
    store: store,
    components: { Hex },
    props: ['open', 'slippage'],
    data() {
        return {
            tokenInName: 'USDC',
            loading: false,
            swapLoading: false,
            amountIn: ethers.utils.parseEther('0'),
            setAmountsIn: {
                xi: ethers.utils.parseEther('0'),
                kappa: ethers.utils.parseEther('0'),
                gamma: ethers.utils.parseEther('0'),
                rho: ethers.utils.parseEther('0'),
                beta: ethers.utils.parseEther('0')
            },
            amountsOut: {
                xi: ethers.utils.parseEther('0'),
                kappa: ethers.utils.parseEther('0'),
                gamma: ethers.utils.parseEther('0'),
                rho: ethers.utils.parseEther('0'),
                beta: ethers.utils.parseEther('0')
            }
        };
    },
    computed: {
        ...mapState(['amountsIn', 'tokenAllowance']),
        checkAllowance() {
            return this.$store.state.settings.tokenAllowance >= this.amountIn;
        },
        checkBalance() {
            return this.$store.state.settings.tokenBalance >= this.amountIn;
        }
    },
    watch: {
        checkAllowance(newVal) {
            if (newVal === true) this.loading = false;
        }
    },
    methods: {
        ...mapActions(['getTokenApproval']),
        getFormattedTotalPrice(decimals) {
            console.log('AmountIN', this.amountIn);
            const slipped = this.amountIn.div(100).mul(this.slippage);
            return parseFloat(ethers.utils.formatUnits(this.amountIn.add(slipped), 6)).toFixed(6);
        },
        getFormattedTokenPrice(price, decimals) {
            return price.toFixed(6);
        },
        setSwap(slippage, amountsOut) {
            this.amountsOut = amountsOut;
            const amountIn = ethers.utils.parseEther('0');
            let xi = this.$store.state.settings.tokenPrices.xi;
            xi = xi
                .div(100)
                .mul(slippage)
                .add(xi);
            let beta = this.$store.state.settings.tokenPrices.beta;
            beta = beta
                .div(100)
                .mul(slippage)
                .add(beta);
            let gamma = this.$store.state.settings.tokenPrices.gamma;
            gamma = gamma
                .div(100)
                .mul(slippage)
                .add(gamma);
            let rho = this.$store.state.settings.tokenPrices.rho;
            rho = rho
                .div(100)
                .mul(slippage)
                .add(rho);
            let kappa = this.$store.state.settings.tokenPrices.kappa;
            kappa = kappa
                .div(100)
                .mul(slippage)
                .add(kappa);
            this.amountIn = xi
                .add(beta)
                .add(rho)
                .add(kappa)
                .add(gamma)
                .add(rho);
            this.setAmountsIn = { xi, rho, beta, gamma, kappa };
        },

        async dispatchTokenApproval() {
            let error;
            this.loading = true;
            const amount = this.amountIn
                .div(100)
                .mul(this.slippage)
                .add(this.amountIn);
            await this.$store.dispatch('getTokenApproval', { amount }).catch(e => {
                error = e;
            });
            if (error) {
                this.loading = false;
            }
        },
        async dispatchSwap() {
            this.loading = true;
            let error;
            await this.$store
                .dispatch('spaceswap', {
                    total: this.amountIn,
                    amounts: this.setAmountsIn,
                    tokens: this.amountsOut
                })
                .catch(e => {
                    error = e;
                });
            console.log(error);
            this.$emit('close');
            this.loading = false;
            return;
        }
    }
};
</script>

<style scoped lang="scss">
.token {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1rem;
    margin: 1rem 1rem 0;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 15px;
    border: 1px solid rgba(255, 255, 255, 0.15);
}

.shell {
    box-shadow: 0px 10px 100px black;
    border-radius: 15px;
}

.out>.token {
    margin: 0rem;
    border-radius: 0;
    border: 0;
}

.out>.token:first-child {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.out>.token:last-child {
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
}

.out {
    margin: 0rem 1rem;
    border-radius: 15px;
    border: 1px solid rgba(255, 255, 255, 0.15);
}

.down {
    width: 40px;
    height: 40px;
    margin: -10px 0 -15px;
    background-color: #232323;
    padding: 0.5rem;
    border-radius: 15px;
    border: 1px solid rgba(255, 255, 255, 0.5);
}

.modal-body {
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    box-shadow: unset !important;
}

.modal-head {
    background: #232323;
    padding: 0.5rem;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    h2 {
        margin: 1rem 2rem;
        margin-bottom: 1rem;
        font-size: 2rem!important;
    }
}

.confirm {
    margin: 1rem;
    padding: 0.5rem 5rem;
    background-color: transparent;
    border: 1px solid white;
    color: white;
    border-radius: 15px;
}

.hex {
    margin: 1rem;
}
</style>
