<template>
  <div class="full-wrapper">
    <div class="about-wrap">
      <a href="mailto:xiprotocol@protonmail.com">
        Contact
      </a>

      <br /><br />
      <span>0x295B42684F90c77DA7ea46336001010F2791Ec8c </span>
      <br /><br />
      <p></p>
      <br />
      <router-link :to="{ name: 'home' }">
        <img
          src="~/@/assets/xi_token_icon_v0.1-48.png"
          style="height:64px;filter:invert(1);"
          alt=""
        />
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
export default {
  data() {
    return {
      form: {
        quantity: ''
      },
      modalLoginOpen: false,
      modalMakepotionOpen: false
    };
  },
  components: {}
};
</script>
<style scoped>
.hasEffect {
  cursor: pointer;
}
</style>
