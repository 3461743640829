<template>
  <Modal :open="open" @close="$emit('close')">
    <div class="modal">
      <div class="backdrop-close"></div>
      <div class="modal-wrapper satcom-card">
        <div class="modal-container">
          <div class="modal-header">
            <img src="~/@/assets/xi.svg" style="width:128px;margin:auto;" alt="" />
          </div>

          <div class="modal-body">
            <div class="modal-box">
                <h1 style="font-weight:500;text-align:center;">SATCOM PAYMENT</h1>
                <br>
                <p> Do not close this window until the process is completed. After the payment is made you must sign a transaction confirming the payment is made to complete the process.</p>
                <br >
                <form class="satcomform">
                    <div class="satcomrow" style="display:flex;flex-flow:row;justify-content:space-between;">
                        <div class="satcomrow-title">
                            <p>Payment Method <b style="color:yellow;"></b>:</p>
                        </div>
                        <div class="satcomrow-input">
                            <select v-model='payment' style="padding:0.5rem 1.5rem;box-shadow:inset 2px 2px 5px #0000005e, inset -2px -2px 5px #ffffff0f;">
                                <option v-for='(key, method) in $store.state.settings.paymentOptions' :key='method' :value='key'>
                                    {{method}}
                                </option>
                            </select>                                    
                        </div>
                    </div>
                </form>
                <h2 v-if='price' style="font-weight:500;text-align:center;">${{formattedPrice}}</h2>
            </div>
            <div class="row" style="margin: 1rem auto; margin-bottom: 1rem">
                <button v-if='isLoading' class="button" style="background-color:#7a7a7b!important;max-width:300px;margin: auto;">                
                                                <img style="width:32px;margin:auto;animation:spinloader 1s ease infinite;" src="~/@/assets/circleopen.svg">
                </button>
                <button v-else-if='price && price.gt(approval)' class="button" style="background-color:#7a7a7b!important;max-width:300px;margin: auto;" v-on:click="dispatchOrbcommApproval">
                    APPROVE
                </button>
                <button v-else-if='price' class="button" style="background-color:#7a7a7b!important;max-width:300px;margin: auto;" v-on:click="dispatchOrbcommPayment">
                    CONFIRM
                </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import countries from '@/helpers/countries.json';
import { ethers } from 'ethers';

export default {
  props: ['open'],
  data() {
    return {
      payment: '',
    };
  },
  computed: { ...mapState({
          price: state => state.settings.orbCommPrice,
          decimals: state => state.settings.priceDecimals,
          approval: state => state.settings.paymentAllowanceUSD,
          boughtOrbcomm: state => state.settings.boughtOrbcomm,
          isLoading: state => state.settings.loading,
      }),
      formattedPrice() {return ethers.utils.formatUnits(this.price, this.decimals)},
  },
  methods: {
        ...mapActions(['getOrbcommPrice', 'orbcommPayment', 'orbcommApproval', 'subscribeToTokenApproval', 'subscribeToPayment']),
      async dispatchOrbcommPayment() {
          await this.subscribeToPayment();
          await this.orbcommPayment(this.payment);
      },
      async dispatchOrbcommApproval() {
          await this.subscribeToTokenApproval(this.payment);
          await this.orbcommApproval(this.payment);
      }
  },
  watch: {
      async payment(val) {
          await this.getOrbcommPrice(val);
      },
      async boughtOrbcomm(val) {
          if(val === true) {
              this.$emit('chain', {showSatcomPaymentModal: false, showSatcomPaymentConfirmedModal: true})
          }
      }

  }

};
</script>
